const profiles = [
  { 
    id: 0,
    type: 'general', 
    title: 'Rutina', 
    checked: false, 
    determinations: [
      { name: 'hemograma', label: 'Hemograma' }, 
      { name: 'got_ast', label: 'GOT-AST' }, 
      { name: 'urea', label: 'Urea' }, 
      { name: 'gpt_alt', label: 'GPT-ALT' }, 
      { name: 'fal', label: 'FAL' }, 
      { name: 'creatinina', label: 'Creatinina' },
    ] 
  },
  { 
    id: 1,
    type: 'general',
    title: 'Pre-quirúrgico', 
    checked: false, 
    determinations: [
      { name: 'hemograma', label: 'Hemograma' }, 
      { name: 'got_ast', label: 'GOT-AST' }, 
      { name: 'urea', label: 'Urea' }, 
      { name: 'gpt_alt', label: 'GPT-ALT' }, 
      { name: 'fal', label: 'FAL' }, 
      { name: 'creatinina', label: 'Creatinina' },
      { name: 'glucosa', label: 'Glucosa' },
      { name: 'proteinas_totales', label: 'Proteínas totales' },
      { name: 'albumina', label: 'Albúmina' },
    ]
  },
  { 
    id: 2,
    type: 'general',
    title: 'Hemostasia', 
    checked: false, 
    determinations: [
      { name: 'hemograma', label: 'Hemograma' }, 
      { name: 'tiempo_tromboplastina', label: 'Tiempo de tromboplastina parcial activada (KPTT)' },
      { name: 'tiempo_protrombina', label: 'Tiempo de protrombina (TP) ' },
    ]
  },
  { 
    id: 3,
    type: 'general',
    title: 'Renal', 
    checked: false, 
    determinations: [
      { name: 'hemograma', label: 'Hemograma' },  
      { name: 'urea', label: 'Urea' }, 
      { name: 'creatinina', label: 'Creatinina' },
      { name: 'glucosa', label: 'Glucosa' },
      { name: 'proteinas_totales', label: 'Proteínas totales' },
      { name: 'albumina', label: 'Albúmina' },
      { name: 'fosforo', label: 'Fósforo' },
    ]
  },
  { 
    id: 4,
    type: 'general',
    title: 'Hepático', 
    checked: false, 
    determinations: [
      { name: 'hemograma', label: 'Hemograma' },
      { name: 'got_ast', label: 'GOT-AST' }, 
      { name: 'urea', label: 'Urea' }, 
      { name: 'gpt_alt', label: 'GPT-ALT' }, 
      { name: 'fal', label: 'FAL' }, 
      { name: 'proteinas_totales', label: 'Proteínas totales' },
      { name: 'albumina', label: 'Albúmina' },
      { name: 'ggt', label: 'GGT' },
    ]
  },
  { 
    id: 5,
    type: 'general',
    title: 'Hepato-renal compl', 
    checked: false, 
    determinations: [
      { name: 'hemograma', label: 'Hemograma' },
      { name: 'got_ast', label: 'GOT-AST' },
      { name: 'urea', label: 'Urea' },
      { name: 'gpt_alt', label: 'GPT-ALT' }, 
      { name: 'fal', label: 'FAL' },
      { name: 'creatinina', label: 'Creatinina' },
      { name: 'glucosa', label: 'Glucosa' },
      { name: 'proteinas_totales', label: 'Proteínas totales' },
      { name: 'albumina', label: 'Albúmina' },
      { name: 'fosforo', label: 'Fósforo' },
      { name: 'ggt', label: 'GGT' },
    ]
  },
  { 
    id: 6,
    type: 'general',
    title: 'Hemopatógenos', 
    checked: false, 
    determinations: [
      { name: 'hemograma', label: 'Hemograma' },
      { name: 'hemopatogenos', label: 'Hemopatógenos' },
    ]
  },
  { 
    id: 7, 
    type: 'general',
    title: 'Geronte', 
    checked: false, 
    determinations: [
      { name: 'hemograma', label: 'Hemograma' },
      { name: 'got_ast', label: 'GOT-AST' },
      { name: 'urea', label: 'Urea' },
      { name: 'gpt_alt', label: 'GPT-ALT' }, 
      { name: 'fal', label: 'FAL' },
      { name: 'creatinina', label: 'Creatinina' },
      { name: 'glucosa', label: 'Glucosa' },
      { name: 'proteinas_totales', label: 'Proteínas totales' },
      { name: 'albumina', label: 'Albúmina' },
      { name: 'fosforo', label: 'Fósforo' },
      { name: 'ggt', label: 'GGT' },
      { name: 'calcio_total', label: 'Calcio Total' },
    ]
  },
  { 
    id: 8,
    type: 'general',
    title: 'Tiroideo', 
    checked: false, 
    determinations: [
      { name: 'colesterol', label: 'Colesterol' },
      { name: 'hemograma', label: 'Hemograma' },
      { name: 'trigliceridos', label: 'Trigliceridos' },
      { name: 'tsh', label: 'TSH' },
      { name: 't4_libre', label: 'T4 Libre' },
    ]
  },
  //New Profiles
  {
    id: 9, 
    type: 'pcr_caninos_felinos',
    title: 'Diarrea canina 1', 
    checked: false, 
    determinations: [
      { name: 'coronavirus_enterico_canino', label: 'Coronavirus Entérico Canino' },
      { name: 'parvovirus_canino', label: 'Parvovirus Canino' },
      { name: 'rotavirus_a', label: 'Rotavirus A' },
    ]
  },
  {
    id: 10, 
    type: 'pcr_caninos_felinos',
    title: 'Diarrea canina 2', 
    checked: false, 
    determinations: [
      { name: 'campylobacters_spp', label: 'Campylobacter spp.' },
      { name: 'coronavirus_enterico_canino', label: 'Coronavirus Entérico Canino' },
      { name: 'cryptosporidium_spp', label: 'Cryptosporidium spp.' },
      { name: 'parvovirus_canino', label: 'Parvovirus Canino' },
      { name: 'rotavirus_a', label: 'Rotavirus A' },
      { name: 'salmonella_spp', label: 'Salmonella spp.' },
    ]
  },
  {
    id: 11, 
    type: 'pcr_caninos_felinos',
    title: 'Diarrea canina 3', 
    checked: false, 
    determinations: [
      { name: 'campylobacters_spp', label: 'Campylobacter spp.' },
      { name: 'cryptosporidium_spp', label: 'Cryptosporidium spp.' },
      { name: 'helicobacter_spp', label: 'Helicobacter spp.' },
      { name: 'salmonella_spp', label: 'Salmonella spp.' },
    ]
  },
  {
    id: 12, 
    type: 'pcr_caninos_felinos',
    title: 'Neurológico canino', 
    checked: false, 
    determinations: [ 
      { name: 'neospora_caninum', label: 'Neospora caninum' },
      { name: 'toxoplasma_gondii', label: 'Toxoplasma gondii' },
      { name: 'virus_distemper_canino', label: 'Virus Distemper Canino' },
    ]
  },
  {
    id: 13, 
    type: 'pcr_caninos_felinos',
    title: 'Oftalmologico canino', 
    checked: false, 
    determinations: [
      { name: 'campylobacters_spp', label: 'Campylobacter spp.' },
      { name: 'cryptosporidium_spp', label: 'Cryptosporidium spp.' },
      { name: 'helicobacter_spp', label: 'Helicobacter spp.' },
      { name: 'salmonella_spp', label: 'Salmonella spp.' },
    ]
  },
  {
    id: 14, 
    type: 'pcr_caninos_felinos',
    title: 'Reproductivo canino', 
    checked: false, 
    determinations: [
      { name: 'brucella_spp', label: 'Brucella spp.' },
      { name: 'chlamydia_spp', label: 'Chlamydia spp.' },
      { name: 'herpes_virus_canino', label: 'Herpes VirusCanino' },
      { name: 'mycoplasma_spp', label: 'Mycoplasma spp.' },
    ]
  },
  {
    id: 15, 
    type: 'pcr_caninos_felinos',
    title: 'Hematico felino', 
    checked: false, 
    determinations: [
      { name: 'ic_vif_vilef', label: 'IC VIF-VILEF' },
      { name: 'mycoplasma_hemotropicos', label: 'Mycoplasma hemotrópicos' },
      { name: 'vif_adn_proviral', label: 'VIF ADN Proviral' },
    ]
  },
  {
    id: 16, 
    type: 'pcr_caninos_felinos',
    title: 'Diarrea felina 1', 
    checked: false, 
    determinations: [
      { name: 'campylobacters_spp', label: 'Campylobacter spp.' },
      { name: 'cryptosporidium_spp', label: 'Cryptosporidium spp.' },
      { name: 'parvovirus_felino', label: 'Parvovirus Felino' },
      { name: 'salmonella_spp', label: 'Salmonella spp.' },
      { name: 'toxoplasma_gondii', label: 'Toxoplasma gondii' },
      { name: 'trichomona_foetus', label: 'Trichomonas foetus' },
    ]
  },
  {
    id: 17, 
    type: 'pcr_caninos_felinos',
    title: 'Diarrea felina 2', 
    checked: false, 
    determinations: [
      { name: 'campylobacters_spp', label: 'Campylobacter spp.' },
      { name: 'cryptosporidium', label: 'Cryptosporidium' },
      { name: 'giardia_spp', label: 'Giardia spp.' },
      { name: 'trichomona_foetus', label: 'Trichomonas foetus' },
    ]
  },
  {
    id: 18, 
    type: 'pcr_caninos_felinos',
    title: ' Respiratorio felino', 
    checked: false, 
    determinations: [
      { name: 'calicivirus_felino', label: 'Calicivirus felino ' },
      { name: 'chlamydia_spp', label: 'Chlamydia spp.' },
      { name: 'herpes_virus_felino', label: 'Herpes Virus Felino' },
      { name: 'mycoplasma_spp', label: 'Mycoplasma spp.' },
    ]
  },
  {
    id: 19, 
    type: 'pcr_caninos_felinos',
    title: 'Oftalmologico felino', 
    checked: false, 
    determinations: [
      { name: 'chlamydia_spp', label: 'Chlamydia spp.' },
      { name: 'herpes_virus_felino', label: 'Herpes Virus Felino' },
      { name: 'mycoplasma_spp', label: 'Mycoplasma spp.' },
    ]
  },
  {
    id: 20, 
    type: 'pcr_caninos_felinos',
    title: 'Reproductivo felino', 
    checked: false, 
    determinations: [
      { name: 'chlamydia_spp', label: 'Chlamydia spp.' },
      { name: 'herpes_virus_felino', label: 'Herpes Virus Felino' },
      { name: 'mycoplasma_spp', label: 'Mycoplasma spp.' },
    ]
  },
  {
    id: 21, 
    type: 'pcr_caninos_felinos',
    title: 'Neurológico felino', 
    checked: false, 
    determinations: [
      { name: 'coronavirus_felino', label: 'Coronavirus Felino' },
      { name: 'toxoplasma_gondii', label: 'Toxoplasma gondii' },
    ]
  },
  
  //PCR EN BOVINOS
  {
    id: 22, 
    type: 'pcr_bovinos',
    title: 'Aborto bovino', 
    checked: false, 
    determinations: [
      { name: 'diarrea_viral_bovina', label: 'Virus de la Diarrea Viral Bovina (VDVB)' },
      { name: 'herpes_virus_bovino', label: 'Herpes Virus Bovino' },
      { name: 'leptospira_interrogans', label: 'Leptospira Interrogans' },
      { name: 'neospora_caninum', label: 'Neospora caninum' },
    ]
  },
  {
    id: 23, 
    type: 'pcr_bovinos',
    title: 'Aborto bovino completo', 
    checked: false, 
    determinations: [
      { name: 'brucella_spp', label: 'Brucella spp.' },
      { name: 'campylobacter_fetus', label: 'Campylobacter fetus' },
      { name: 'diarrea_viral_bovina', label: 'Virus de la Diarrea Viral Bovina (VDVB)' },
      { name: 'herpes_virus_bovino', label: 'Herpes Virus Bovino' },
      { name: 'leptospira_interrogans', label: 'Leptospira Interrogans' },
      { name: 'neospora_caninum', label: 'Neospora caninum' },
      { name: 'trichomona_foetus', label: 'Trichomonas foetus' },
    ]
  },
  {
    id: 24, 
    type: 'pcr_bovinos',
    title: 'Toro pre servicio', 
    checked: false, 
    determinations: [
      { name: 'campylobacter_fetus', label: 'Campylobacter fetus' },
      { name: 'trichomona_foetus', label: 'Trichomonas foetus' },
    ]
  },
  {
    id: 25, 
    type: 'pcr_bovinos',
    title: 'Diarrea neonatal bovina basico', 
    checked: false, 
    determinations: [
      { name: 'coronavirus_bovino', label: 'Coronavirus Bovino' },
      { name: 'cryptosporidium_spp', label: 'Cryptosporidium spp. ' },
      { name: 'escherichia_coli', label: 'Escherichia coli ET (k99+)' },
      { name: 'rotavirus_bovino_a', label: 'Rotavirus Bovino A' },
    ]
  },
  {
    id: 26, 
    type: 'pcr_bovinos',
    title: 'Diarrea neonatal completo', 
    checked: false, 
    determinations: [
      { name: 'coronavirus_bovino', label: 'Coronavirus Bovino' },
      { name: 'cryptosporidium_spp', label: 'Cryptosporidium spp. ' },
      { name: 'escherichia_coli', label: 'Escherichia coli ET (k99+)' },
      { name: 'rotavirus_bovino_a', label: 'Rotavirus Bovino A' },
      { name: 'salmonella_spp', label: 'Salmonella spp.' },
      { name: 'staphylococcus_aureus', label: 'Staphylococcus aureus' },
      { name: 'yersinia_enterocolitica', label: 'Yersinia enterocolitica' },
    ]
  },
  {
    id: 27, 
    type: 'pcr_bovinos',
    title: 'Analisis seminal bovino', 
    checked: false, 
    determinations: [
      { name: 'diarrea_viral_bovina', label: 'Virus de la Diarrea Viral Bovina (VDVB)' },
      { name: 'herpes_virus_bovino', label: 'Herpes Virus Bovino' },
      { name: 'leucosis_bovina', label: 'Virus de la Leucosis Bovina' },
    ]
  },
  {
    id: 28, 
    type: 'pcr_bovinos',
    title: 'Anemia bovina', 
    checked: false, 
    determinations: [
      { name: 'anaplasma_spp', label: 'Anaplasma spp.' },
      { name: 'babesia_spp', label: 'Babesia spp.' },
    ]
  },
  {
    id: 29, 
    type: 'pcr_bovinos',
    title: 'Neurológico neonatal', 
    checked: false, 
    determinations: [
      { name: 'herpes_virus_bovino', label: 'Herpes Virus Bovino' },
      { name: 'leucosis_bovina', label: 'Virus de la Leucosis Bovina' },
      { name: 'neospora_caninum', label: 'Neospora caninum' },
    ]
  },
  //PCR EN AVES
  {
    id: 30, 
    type: 'pcr_aves',
    title: 'PCR Aves', 
    checked: false, 
    determinations: [
      { name: 'adenovirus_aviar', label: 'Adenovirus Aviar (FAdV)' },
      { name: 'aspergillus_spp', label: 'Aspergillus spp.' },
      { name: 'bronquitis_infecciosa_aviar', label: 'Bronquitis infecciosa aviar' },
      { name: 'chlamydia_psittaci', label: 'Chlamydia Psittaci' },
      { name: 'chlamydia_spp', label: 'Chlamydia spp.' },
      { name: 'cryptosporidium_spp', label: 'Cryptosporidium spp. ' },
      { name: 'cryptococcus_neoformans', label: 'Cryptococcus Neoformans' },
      { name: 'enfermedad_pacheco', label: 'Enfermedad de Pacheco (PsHV)' },
      { name: 'enfermedad_marek', label: 'Enfermedad de Marek (GaHV-2)' },
      { name: 'enfermedad_pico_pluma', label: 'Enfermedad de pico y pluma' },
      { name: 'larinotraqueitis_infecciosa', label: 'Larinotraqueitis infecciosa (ILTV)' },
      { name: 'mycoplasmas_ave', label: 'Mycoplasma de ave' },
      { name: 'poliomavirus_aviar', label: 'Poliomavirus Aviar (APV)' },
      { name: 'salmonella_spp', label: 'Salmonella spp.' },
    ]
  },
  //PCR EN EQUINOS
  {
    id: 31, 
    type: 'pcr_equinos',
    title: 'PCR Equinos', 
    checked: false, 
    determinations: [
      { name: 'babesia_spp', label: 'Babesia spp.' },
      { name: 'influenza_equina', label: 'Influenza equina H3N8' },
      { name: 'leptospira_interrogans', label: 'Leptospira Interrogans' },
      { name: 'rhodoccocus_spp', label: 'Rhodoccocus spp.' },
      { name: 'rotavirus', label: 'Rotavirus' },
      { name: 'salmonella_spp', label: 'Salmonella spp.' },
      { name: 'streptococcus_equi', label: 'Streptococcus equi' },
    ]
  },
  //AGENTES INFECCIOSOS Y PARASITARIOS EN PEQUEÑOS
  {
    id: 32, 
    type: 'agentes_infecciosos_parasitarios',
    title: 'Pequeños animales', 
    checked: false, 
    determinations: [
      { name: 'adenovirus_canino', label: 'Adenovirus Canino (Diferencial tipos 1 y 2)' },
      { name: 'anaplasma_platys', label: 'Anaplasma platys' },
      { name: 'babesia_spp', label: 'Babesia spp.' },
      { name: 'babesia_gibsoni', label: 'Babesia gibsoni' },
      { name: 'brucella_spp', label: 'Brucella spp.' },
      { name: 'calicivirus_felino', label: 'Calicivirus felino' },
      { name: 'campylobacters_spp', label: 'Campylobacter spp.' },
      { name: 'chlamydia_spp', label: 'Chlamydia spp.' },
      { name: 'circovirus_canino', label: 'Circovirus Canino' },
      { name: 'clostridium_perfringens', label: 'Clostridium Perfringens' },
      { name: 'coronavirus_enterico_canino', label: 'Coronavirus Entérico Canino' },
      { name: 'coronavirus_felino', label: 'Coronavirus Felino' },
      { name: 'coronavirus_respiratorio_canino', label: 'Coronavirus Respiratorio Canino' },
      { name: 'cryptococcus_neoformans', label: 'Cryptococcus Neoformans' },
      { name: 'cryptosporidium_spp', label: 'Cryptosporidium spp.' },
      { name: 'cuantificacion_adn_proviral', label: 'Cuantificación del ADN proviral de VILEF por PCR en tiempo real' },
      { name: 'cytauxzoon_felis', label: 'Cytauxzoon Felis' },
      { name: 'dirofilaria_inmitis', label: 'Dirofiliaria inmitis ' },
      { name: 'distemper_canino', label: 'Distemper canino' },
      { name: 'ehrlichia_canis', label: 'Ehrlichia canis' },
      { name: 'ehrlichiosis_spp_anaplasmosis_spp', label: 'Ehrlichiosis spp. + Anaplasmosis spp.' },
      { name: 'filarias_spp', label: 'Filarias spp.' },
      { name: 'giardia_spp', label: 'Giardia spp.' },
      { name: 'helicobacter_spp', label: 'Helicobacter spp.' },
      { name: 'hepatozoon_canis', label: 'Hepatozoon canis' },
      { name: 'hepatozoon_spp', label: 'Hepatozoon spp.' },
      { name: 'herpes_virus_canino', label: 'Herpes Virus Canino' },
      { name: 'herpes_virus_felino', label: 'Herpes Virus Felino' },
      { name: 'leptospira_interrogans', label: 'Leptospira Interrogans' },
      { name: 'microsporum_canis', label: 'Microsporum canis' },
      { name: 'mycoplasma_turicensis', label: 'Mycoplasma turicensis' },
      { name: 'mycoplasma_haemofelis', label: 'Mycoplasma haemofelis' },
      { name: 'mycoplasma_hemotropicos', label: 'Mycoplasma hemotropicos' },
      { name: 'mycoplasma_spp', label: 'Mycoplasma spp.' },
      { name: 'neospora_caninum', label: 'Neospora caninum' },
      { name: 'panleucopenia', label: 'Panleucopenia Felina' },
      { name: 'parvovirus_canino', label: 'Parvovirus Canino' },
      { name: 'rotavirus_a', label: 'Rotavirus A' },
      { name: 'salmonella_spp', label: 'Salmonella spp.' },
      { name: 'sars_cov_2', label: 'SARS-CoV-2' },
      { name: 'toxoplasma_gondii', label: 'Toxoplasma gondii' },
      { name: 'trichomona_foetus', label: 'Trichomona foetus' },
      { name: 'inmunodeficiencia_felina_arn', label: 'Virus de la inmunodeficiencia felina ARN viral' },
      { name: 'inmunodeficiencia_felina_provirus', label: 'Virus de la inmunodeficiencia felina provirus' },
      { name: 'leucemia_felina_arn', label: 'Virus de la leucemia felina ARN viral' },
      { name: 'leucemia_felina_provirus', label: 'Virus de la leucemia felina provirus' },
    ]
  },
  //AGENTES INFECCIOSOS Y PARASITARIOS EN BOVINOS
  {
    id: 33, 
    type: 'agentes_infecciosos_parasitarios',
    title: 'Bovinos', 
    checked: false, 
    determinations: [
      { name: 'anaplasma_spp', label: 'Anaplasma spp.' },
      { name: 'babesia_spp', label: 'Babesia spp.' },
      { name: 'brucella_abortus', label: 'Brucella abortus' },
      { name: 'brucella_spp', label: 'Brucella spp.' },
      { name: 'campylobacter_fetus', label: 'Campylobacter fetus' },
      { name: 'campylobacter_fetus_venerialis', label: 'Campylobacter fetus veneralis' },
      { name: 'campylobacters_spp', label: 'Campylobacter spp.' },
      { name: 'chlamydia_spp', label: 'Chlamydia spp.' },
      { name: 'coronavirus_bovino', label: 'Coronavirus Bovino' },
      { name: 'cryptosporidium_spp', label: 'Cryptosporidium spp.' },
      { name: 'escherichia_coli', label: 'Escherichia coli ET (k99+)' },
      { name: 'helicobacter_spp', label: 'Helicobacter spp.' },
      { name: 'herpes_virus_bovino', label: 'Herpes Virus Bovino' },
      { name: 'histophilus_somni', label: 'Histophilus somni' },
      { name: 'leptospira_interrogans', label: 'Leptospira Interrogans' },
      { name: 'leucosis_bovina', label: 'Virus de la Leucosis Bovina' },
      { name: 'mycoplasma_spp', label: 'Mycoplasma spp.' },
      { name: 'neospora_caninum', label: 'Neospora caninum' },
      { name: 'rotavirus_a', label: 'Rotavirus A' },
      { name: 'rotavirus_bovino_a', label: 'Rotavirus Bovino A' },
      { name: 'salmonella_spp', label: 'Salmonella spp.' },
      { name: 'staphylococcus_aureus', label: 'Staphylococcus aureus' },
      { name: 'trichomonas_spp', label: 'Trichomonas spp.' },
      { name: 'trichomona_foetus', label: 'Trichomonas foetus' },
      { name: 'diarrea_viral_bovina', label: 'Virus de la Diarrea Viral Bovina (VDVB)' },
      { name: 'yersinia_enterocolitica', label: 'Yersinia enterocolítica' }
    ]
  },

]

export default profiles