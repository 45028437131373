import moment from 'moment';

export function calculateAgeFromDate(date) {
    if(date) {
      const today = moment();
      const birthDate = moment(date);
      const years = today.diff(birthDate, 'years');
      birthDate.add(years, 'years');
      const months = today.diff(birthDate, 'months');
      return { years: years, months: months };
    } else {
      return null;
    }
}