<template>
  <div>
    <v-form ref="determinationDataForm">
      <div class="mt-6 pe-3">
        <!-- <v-card-title> Determinaciones</v-card-title> -->

        <template v-if="loading">
          <div class="text-center pb-3 pt-2">CARGANDO DETERMINACIONES...</div>
          <v-skeleton-loader
            loading
            class="mx-auto my-loader"
            type="table-thead ,divider, table-row@5, divider"
          ></v-skeleton-loader>
        </template>

        <v-row v-else class="justify-center">
          <v-tabs v-model="tab" show-arrows>
            <v-tab
              active-class="active-tab"
              v-for="(list, index) in determinationLists"
              :key="index"
            >
              <span>{{ list[0] ? list[0].special_category.label : "-" }}</span>
              <v-badge v-show="selectedCategories[`cat${list[0].special_category.id}`] && selectedCategories[`cat${list[0].special_category.id}`].size > 0"
                color="primary"
                :content="selectedCategories[`cat${list[0].special_category.id}`] ? selectedCategories[`cat${list[0].special_category.id}`].size : null"
                right
                class="mb-3"
              >
              </v-badge>
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab" style="width: 100%">
            <v-tab-item
              class="tabs-item"
              v-for="(list, index) in determinationLists"
              :key="index"
            >
              <v-card-text class="mt-3">
                <v-row dense no-gutters>
                  <v-col
                    class="d-flex v-list-item"
                    v-for="determination in list"
                    :key="determination.id"
                    cols="4"
                  >
                    <!-- :disabled="determinationsDisabled.includes(determination.id)" -->
                    <v-checkbox
                      :disabled="initialData.viewMode"
                      :ref="`checkbox_${determination.id}`"
                      :input-value="selectedItems.includes(determination.id)"
                      @change="updateSelectedItems(determination, $event)"
                      dense
                    >
                    </v-checkbox>
                    <v-card-title class="caption pa-0 mb-2">{{
                      determination.readable
                    }}</v-card-title>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-tab-item>
          </v-tabs-items>
        </v-row>
      </div>
    </v-form>
  </div>
</template>

<script>
import AppCardActions from "@core/components/app-card-actions/AppCardActions.vue";

export default {
  props: {
    profilesModifications: {
      type: Object,
      default: () => {},
    },
    initialData: {
      type: Object,
      default: () => {},
    },
  },
  data: () => {
    return {
      loading: true,
      disabled: false,
      determinationsDisabled: [],
      profileList: {},
      selectedItems: [],
      selectedCategories: {},
      rawDeterminationList: [],
      determinationLists: [],
      test: [],
      tab: null,
    };
  },
  created() {
    this.getDeterminationsList();
  },
  methods: {
    async getDeterminationsList() {
      const data = {
        paginated: false,
        summary: true,
      };
      try {
        let response = await this.$api.getDeterminationsList(data);
        const parsedResponse = JSON.parse(JSON.stringify(response));
        this.rawDeterminationList = parsedResponse;

        let categories = {
          // 'principal': [],
          análisis_dermatológicos: [],
          análisis_de_orina: [],
          bacteriología: [],
          biología_molecular: [],
          bioquímica_sanguínea: [],
          compatibilidad_sanguínea: [],
          endocrinología: [],
          examen_de_materia_fecal: [],
          hematología: [],
          hemostasia_tiempos_de_coagulación: [],
          inmunodiagnóstico_y_serología: [],
          ionograma: [],
          líquidos_de_punción: [],
          patología: [],
          plasmasuero: [],
          toxicología: [],
        };

        parsedResponse.forEach((el) => {
          if (categories[el.special_category.name]) {
            categories[el.special_category.name].push(el);
            this.selectedCategories[`cat${el.special_category.id}`] = new Set()
          }
        });

        this.determinationLists = [];
        Object.keys(categories).forEach((key) => {
          if (categories[key].length > 0) {
            this.determinationLists.push(categories[key]);
          }
        });

        this.setInitialData();
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    updateSelectedItems(determination, isChecked) {
      // console.log(this.selectedItems);
      if (isChecked) {
        // agregar IDs a la matriz selectedItems si no existen todavía
        this.selectedItems.push(determination.id);
        this.selectedCategories[`cat${determination.special_category.id}`].add(determination.id) 
        
      } else {
        // Eliminar IDs a la matriz selectedItems si ya existen
        const index = this.selectedItems.indexOf(determination.id);
        if (index > -1) {
          this.selectedItems.splice(index, 1);
        }
        if(this.selectedCategories[`cat${determination.special_category.id}`]){
          this.selectedCategories[`cat${determination.special_category.id}`].delete(determination.id)
        }
      }
    },
    setInitialData() {
      if (this.initialData.determinations && this.initialData.determinations.length) {
        this.initialData.determinations.forEach(det => {
          this.selectedCategories[`cat${det.special_category}`].add(det.id) 
          this.selectedItems.push(det.id)
        })
      } else {
        this.selectedItems = [];
        for (var cat in this.selectedCategories) this.selectedCategories[cat] = new Set();
      }

    },
    reset() {
      this.$refs.determinationDataForm.reset();
      this.selectedItems = [];
    },
  },
  computed: {
    
  },
  watch: {
    initialData: {
      handler: function (newVal) {
        if (newVal) {
          this.setInitialData();
        }
      },
      deep: true,
    },

    selectedItems(val) {
      this.$emit("update-request-data", this.selectedItems);
    },
    
    profilesModifications(val) {
      if (Object.keys(this.profilesModifications).length) {
        const profileStatus = val.profileStatus;
        const determinations = val.determinations;

        if (profileStatus) {
          this.profileList[val.profileName] = val.determinations; //Creo un objeto auxiliar con una key por cada nombre de perfiles que se agregan
        } else {
          delete this.profileList[val.profileName]; //elimino el perfil cuando venga false
        }

        const determinationSet = new Set(determinations);
        const filteredDeterminations = this.rawDeterminationList.filter((det) =>
          determinationSet.has(det.name)
        );

        filteredDeterminations.forEach((det) => {
          if (profileStatus) {
            // this.determinationsDisabled.push(id)
            if (!this.selectedItems.includes(det.id)) {
              this.selectedItems.push(det.id);
              if(!this.selectedCategories[`cat${det.special_category.id}`]){
                this.selectedCategories[`cat${det.special_category.id}`] = new Set()
                this.selectedCategories[`cat${det.special_category.id}`].add(det.id) 
              } 
              else { this.selectedCategories[`cat${det.special_category.id}`].add(det.id) }
              
            }
          } else {
            const index = this.selectedItems.indexOf(det.id);
            if (index > -1) {
              this.selectedItems.splice(index, 1);
            }
            if(this.selectedCategories[`cat${det.special_category.id}`]){
              this.selectedCategories[`cat${det.special_category.id}`].delete(det.id)
            }
          }
        });

        this.$emit("reset-profile-modif");
      }
    },
  },
  components: { AppCardActions },
};
</script>

<style lang="scss" scoped>
.v-list-item {
  height: 32px !important;
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.v-tab {
  font-size: 0.6rem;
}

.active-tab {
  background-color: rgba(231, 227, 252, 0.87);
}
.my-loader {
  width: 100%;
  max-width: 750px;
}

@media screen and (max-width: 1450px) {
  .my-loader {
    width: 100%;
    max-width: 550px;
  }
}
</style>
