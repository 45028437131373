<template>
  <v-dialog 
    class="hide-scroll" 
    v-model="showingInvoiceModal" 
    persistent
    fullscreen
  >
    <!-- max-width="1000" -->
    <!-- fullscreen hide-overlay -->
    <v-progress-circular v-if="loading"
      color="primary"
      bg-color="red"
      class="loading-circle"
      indeterminate
    ></v-progress-circular>
    
      <v-card class="modal-background hide-scroll">
      
        <v-app-bar elevation="3" class="tool-bar invoice-modal-header">
          <v-toolbar-title class="text-h5 grey lighten-2" >{{ createInvoiceMode? 'Nuevo Remito' : `Solicitud: ${request_sku}` }}
          </v-toolbar-title>

          <v-chip
            v-if="!createInvoiceMode"
            class="ml-4"
            :color="status_color"
            small
          >
            {{ status_invoice.status || 'Sin definir' }}
          </v-chip>

          <v-btn icon color="primary" class="close-btn" @click="close()">
            
          <v-icon>{{ icons.mdiClose }}</v-icon>
          </v-btn>
        </v-app-bar>
        <!-- <v-divider class="mt-n2"></v-divider> -->
        <v-row class="invoice-body">

          <v-col 
            class="mt-3" 
            cols="12"
          >
            <v-card style="margin: 0.5rem">
              <v-card-title class="pt-5"> Datos del Remito</v-card-title>
              <v-divider class="ms-3 me-3"></v-divider>

              <v-form ref="payment_form">
                <v-container>
                  <v-row v-if="createInvoiceMode">
                    <v-col cols="6" class="text-center">
                      <div class="text-center mr-3">
                        <v-select
                          v-model="selected_customer_type"
                          :items="customerTypeList"
                          @change="error_to_show = ''"
                          item-value="id"
                          item-text="name"
                          outlined
                          dense
                          label="Cliente categoría"
                        ></v-select>
                      </div>
                    </v-col>
                    <v-col v-if="selected_customer_type === 0" cols="6" class="text-center">
                      <v-autocomplete
                        v-model="selected_veterinary_user"
                        append-icon=""
                        :prepend-inner-icon="icons.mdiMagnify"
                        :items="veterinaryUsersList"
                        :search-input.sync="searchVet"
                        item-text="name" 
                        item-value="id"
                        outlined
                        dense
                        hide-details
                        label="Médico Veterinario"
                      >
                        <!-- cache-items -->
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-title>
                              No se encontraron
                              <strong>Veterinarios</strong>
                            </v-list-item-title>
                          </v-list-item>
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col v-if="selected_customer_type === 1" cols="6" class="text-center">
                      <v-autocomplete
                        v-model="selected_tutor"
                        append-icon=""
                        :prepend-inner-icon="icons.mdiMagnify"
                        :items="tutorList"
                        :search-input.sync="searchTutor"
                        item-text="name" 
                        item-value="id"
                        outlined
                        dense
                        hide-details
                        label="Tutor"
                      >
                        <!-- cache-items -->
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-title>
                              No se encontraron
                              <strong>Tutores</strong>
                            </v-list-item-title>
                          </v-list-item>
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col v-if="selected_customer_type === 2" cols="6" class="text-center">
                      <v-text-field
                        ref="name"
                        v-model="other_customer"
                        outlined
                        dense
                        autocomplete="off"
                        hide-details="auto"
                        label="Nombre del cliente"
                      />
                    </v-col>
                    <v-col v-if="selected_customer_type === 2" cols="6" class="text-center">
                      <v-text-field
                        v-model="client_email"
                        outlined
                        dense
                        autocomplete="off"
                        hide-details="auto"
                        label="Email del cliente"
                      />
                    </v-col>
                  </v-row>

                  <v-row v-if="!invoice_items.length">
                    <v-col class="text-center">
                      {{ createInvoiceMode? 'Agrega una referencia y monto al remito a crear' : 'Edición del remito' }}
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <v-text-field
                        label="Referencia"
                        dense
                        outlined
                        hide-details="auto"
                        :rules="[value => !!value || 'Este campo es requerido.']"
                        v-model="invoice_items.reference"
                      />
                    </v-col>

                    <v-col
                      cols="10"
                      sm="4"
                    >
                      <v-text-field
                        label="Valor"
                        dense
                        outlined
                        hide-details="auto"
                        prefix="$"
                        v-model.number="invoice_items.price"
                        :rules="[value => value >= 0 || 'El valor no puede ser menor a 0']"
                        type="number"
                      />
                    </v-col>
                  </v-row>

                  <v-row v-if="error_to_show.length" style="color: #ff4c51">
                    <v-col>
                      {{ error_to_show }}
                    </v-col>
                  </v-row>

                </v-container>
              </v-form>
              
            </v-card>
          </v-col>

        </v-row>
        
        <v-app-bar elevation="5" class="tool-bar invoice-modal-footer">
          <v-spacer></v-spacer>
          
          <v-card-actions class="pa-3" >
            <span class="text-2xl font-weight-medium mr-4" style="color: #555">
              TOTAL: ${{ total_invoice }}
            </span>
            <v-btn
              color="primary"
              class="mb-3 mt-3 me-3"
              @click="createInvoiceMode? createInvoice() : showEditInvoice()"
            >
              <!-- :disabled="report.status !== 0" -->
              {{ createInvoiceMode? 'Crear Remito' : 'Editar Remito' }}   <v-icon class="ml-2">{{ icons.mdiCheck }}</v-icon>
            </v-btn>

          </v-card-actions>

        </v-app-bar>
      </v-card>
  
      <v-dialog
        v-model="showingEditInvoiceModal"
        width="auto"
      >
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            ¿Editar los items del remito?
          </v-card-title>
          <v-divider></v-divider>
  
          <v-card-actions>
            <v-spacer></v-spacer>
            
            <v-btn
              color="error"
              outlined
              @click="() => showingEditInvoiceModal = !showingEditInvoiceModal"
            >
              CANCELAR
            </v-btn>         
            <v-btn
              color="success" 
              @click="createPayment()"
            >
              Editar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

  </v-dialog>
</template>

<script>
import debounce from '../../plugins/debounce.js'
import { 
  mdiClose,
  mdiCheck,
  mdiSendOutline,
  mdiEyeOutline,
  mdiPencilOutline,
  mdiMagnify,
  mdiDeleteOutline,
} from '@mdi/js'

export default {
  data: () => {
    return {

      //pagination
      page_size: 10,
      searchVet: '',
      searchTutor: '',
      count: 0,
      page: 1,
      
      statusList: [{ id: 0, label: 'Pendiente' }, { id: 1, label: 'Finalizado' },],
      
      loading: false,
      showingInvoiceModal: false,
      showingEditInvoiceModal: false,
      attached_file: null,
      observations: "",
      createInvoiceMode: false,
      allRequestList: [],
      customerTypeList: [
        {id:0 , name: 'Veterinario'},
        {id:1 , name: 'Tutor'},
        {id:2 , name: 'Otros'}
      ],
      veterinaryUsersList: [],
      tutorList: [],
      request_sku: '',
      
      payment_method: null,

      error_to_show: '',
      selected_customer_type: null,
      selected_veterinary_user: null,
      selected_tutor: null,
      other_customer: '',
      client_email: '',
      invoice: {},
      invoice_items: {},

    }
  },
  filters: {
    formatShowingDate(value) {
      if (!value) return '-';

      const [year, month, day] = value.split('-')
      return `${day}/${month}/${year}`
    },
  },
  methods: {
    async openModal(id) {
      if (id) {

        this.loading = true
        try {
          const response = await this.$api.getOneInvoice(id)
  
          this.invoice = response
          this.request_sku = response.client?.sku
          this.invoice_items = response.items
        } catch(e) {
          console.log(e)
        } finally {
          this.loading = false
        }

      } else {
        try {
          this.getRequestList()
        } catch (error) {
          
        }
        this.createInvoiceMode = true

      }

      this.showingInvoiceModal = true

    },

    addInvoiceItem() {
      this.error_to_show = ''
      this.invoice_items.push({ reference: '', price: null })
    },

    deleteInvoiceItem(idx) {
      this.invoice_items.splice(idx, 1)
    },

    resetSelections() {
      this.count = 0
      this.page = 1
      this.selected_veterinary_user = null
      this.selected_tutor = null
      this.other_customer = ''
    },

    async getVeterinaryUsersList() {
      try {
        const data = {
          page_size: this.page_size,
          page: this.page,
          search: this.search,
          summary: true,
          role: 0, // Rol Veterinario = 0
        };
        const response = await this.$api.getUsersList(data);
        this.veterinaryUsersList = response.results;
        // console.log('LIST', this.veterinaryUsersList);
        this.count = response.count;
      } catch (error) {
        // console.log(error);
      }
    },

    async getTutorList() {
      try {
        const data = {
          page_size: this.page_size,
          page: this.page,
          search: this.search,
          summary: true,
        };
        const response = await this.$api.getTutorsList(data);
        this.tutorList = response.results;
        // console.log('LIST', this.veterinaryUsersList);
        this.count = response.count;
      } catch (error) {
        // console.log(error);
      }
    },

    async getRequestList() {
      if (!this.allRequestList.length) {
        try {
          const response = await this.$api.getRequestList({ summary: true })
          // console.log(response);
          this.allRequestList = response
        } catch (error) {
          console.log(error);
        }
      }
    },

    showEditInvoice() {
      this.showingEditInvoiceModal = !this.showingEditInvoiceModal
    },

    async createInvoice() {
      
      if (!this.invoice_items.reference || !this.invoice_items.price || this.invoice_items.price <= 0) {
        this.error_to_show = 'Es necesario que agregués una referencia y un valor.' 
        return
      }
      this.error_to_show = ''
      
      if (!this.selected_veterinary_user && !this.selected_tutor && !this.other_customer) {
        this.error_to_show = 'Introduce un cliente al cual vincular el remito.' 
        return
      }
          
      if (this.$refs.payment_form.validate()) {
        try {
          const rawForm = {
            client: this.other_customer,
            client_email: this.client_email,
            user: this.selected_veterinary_user,
            tutor: this.selected_tutor,
            item: this.invoice_items
          }

          // Obtener las propiedades con un valor definido en el objeto 'form'
            const keysWithValues = Object.keys(rawForm).filter(key => rawForm[key]);

          // Crear un nuevo objeto solo con las propiedades que tienen un valor definido
          const form = {};
          keysWithValues.forEach(key => {
            form[key] = rawForm[key];
          });

          await this.$api.createInvoice(form)
          this.$emit('refresh-list')
          this.$emit('show-alert', { message: 'Remito creado correctamente', type: 'success' })
          this.close()
        } catch (error) {
          console.log(error);
          this.error_to_show = 'Ocurrió un error. Por favor, intente de nuevo más tarde.'
        }
      }

    },

    async createPayment() {
      if (this.$refs.payment_form.validate()) {
        
        const form = {
          reference: this.invoice_items.reference,
          price: this.invoice_items.price
        }

        try {
          /* const response =  */await this.$api.updateInvoiceItems(form, this.invoice_items.id)
          // console.log(response);

          this.$emit('show-alert', { message: 'Remito editado correctamente', type: 'success' })
          this.$emit('refresh-list')
          this.showingInvoiceModal = !this.showingInvoiceModal
          this.showingEditInvoiceModal = !this.showingEditInvoiceModal
        } catch (error) {
          console.log(error);
        }
      }

    },


    close() {
      this.invoice_items = {}
      this.error_to_show = ''
      this.selected_tutor = null
      this.selected_veterinary_user = null
      this.createInvoiceMode = false
      this.showingInvoiceModal = false
    },
    
    validateAllInputs() {
      let validToSend = true
      for (const key in this.form) {
        if(this.form[key] === '') {
          validToSend = false
        }
      }
      return validToSend
    },
    
    
  },
  computed: {
    payment_methods() {
      return [
          { id: 0, label: 'Efectivo' }, 
          { id: 1, label: 'Transferencia' },
          { id: 2, label: 'Tarjeta de Crédito' },
          { id: 3, label: 'Tarjeta de Débito' },
          { id: 4, label: 'Mercado Pago'}
        ]
    }, 
    status_invoice() { return this.invoice.status ?? {} },
    status_color() {
      const status =  this.invoice?.status?.status
      return status
        ? status === 'Pendiente'
          ? '#6D8CD4'
          : '#75B67C'
        : '#778899'
    },

    total_invoice() {
      return this.invoice_items.price
      // GUARDAR PARA FUTURAS FEATURES
      // return this.invoice_items.reduce((accumulator, current) => Number(accumulator) + Number(current.price), 0)
    },
  },
  watch:{
    // watchStatus(newVal) {
    //   newVal === 0 ? this.viewMode = false : this.viewMode = true
    // },
    selected_customer_type(newVal) {
      this.resetSelections()
      if(newVal === 0) this.getVeterinaryUsersList()
      if(newVal === 1) this.getTutorList()
    },
    searchVet: debounce(function () {
      this.page = 1;
      this.getVeterinaryUsersList();
    }, 400),
    searchTutor: debounce(function () {
      this.page = 1;
      this.getTutorList();
    }, 400)
  },
  components: {
    // AlertPopUp
  },
  setup() {
    return {
      icons: {
        mdiClose,
        mdiCheck,
        mdiSendOutline,
        mdiEyeOutline,
        mdiPencilOutline,
        mdiMagnify,
        mdiDeleteOutline,
      }
    }
  }
}
</script>

<style scoped>
.hide-scroll{
  overflow-y: hidden !important;
  overflow-x: hidden;
}

.modal-background {
  background-color: #f4f5fa;
  overflow-y: hidden;
  overflow-x: hidden;
}

.loading-circle {
  position: fixed;
  left: 50%;
  top: 3%;
  z-index: 10;
}

.close-btn {
  position: absolute; 
  right: 0; 
  padding: 1rem !important; 
  margin: 0.8rem !important
}

.invoice-modal-header {
  position: fixed;
  z-index: 1;
}

.invoice-modal-footer {
  position: fixed;
  bottom: 0;
  z-index: 1;
}

.invoice-body {
  margin: 4rem auto; 
  max-width: 1000px;
}
</style>