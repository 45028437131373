<template>
  <v-dialog
    v-model="isDialogVisible"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card tile class="d-flex">
    
      <v-app-bar fixed dense height="70" dark color="#750D7C">
        <v-btn class="ms-2 btn1" icon xl dark @click="closeModal">
          <v-icon>{{ icons.mdiClose }}</v-icon>
        </v-btn>
        <v-divider class="mx-4" vertical></v-divider>
        <v-toolbar-title class="ms-3 toolbar-text">
        {{isEditing ? 'SOLICITUD DE ANALISIS' :'CREAR SOLICITUD DE ANALISIS'}}
        </v-toolbar-title>
        <v-spacer></v-spacer> 
        
        
          <v-btn v-if="!editingRequest.viewMode" 
          color="rgb(83 153 31)" 
          class="my-auto toolbar-text" 
          width="180" 
          :disabled="!validateAll"
          @click="sendForm"
          >
            {{isEditing ? 'Editar Solicitud' : 'Crear Solicitud'}}
          </v-btn>
          
          <v-chip v-else> Modo lectura</v-chip>
        
      </v-app-bar>
      <!-- style="background-color: #f4f5fa;" -->
      <v-card class="d-flex">
        <v-col cols="7" class="pa-0">
          <RequestFormHead
          ref="request_data_form"
          @update-request-data="updateFormData"
          @is-valid-to-send="setCompValidation"
          :initialData="editingRequest"
          />

          <RequestFormBody
          ref="determination_data_form"
          @update-request-data="updateDeterminationsData"
          :profilesModifications="profilesModifications"
          @reset-profile-modif="profilesModifications = {}"
          :initialData="editingRequest"
          />

        </v-col>
        <v-col cols="5" class="pa-0 mb-0">
          <RequestFormSidebar 
          ref="extra_data_form"
          @update-request-data="updateExtraData"
          @is-valid-to-send="setCompValidation"
          @set-profiles-det="profilesDeterminations"
          :initialData="editingRequest"
          />
        </v-col>
      </v-card>
    
      <v-dialog
        v-model="showAlert"
        width="auto"
      >
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            {{ responseMessage.title }}
          </v-card-title>
  
          <v-divider></v-divider>
  
          <v-card-actions>
            <v-spacer></v-spacer>
  
            <v-btn 
            color="primary" 
            text 
            @click="closeModal"
            >
              OK
            </v-btn>         
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="showProtocolModal"
        width="auto"
      >
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            <v-icon class="me-2">{{ icons.mdiAlertOutline }}</v-icon>
            {{ responseMessage.title }}
          </v-card-title>
          <v-card-subtitle class=" mt-2" v-html="responseMessage.subtitle">
            
          </v-card-subtitle>
          <v-divider></v-divider>
  
          <v-card-actions>
            <v-spacer></v-spacer>
  
            <v-btn 
            color="error" 
            outlined
            text 
            @click="cancelProtocolModal"
            >
              cancelar
            </v-btn>  

            <v-btn 
            color="primary" 
            @click="confirmProtocolModal"
            >
              CREAR
            </v-btn> 

          </v-card-actions>
        </v-card>
      </v-dialog>

    </v-card>
  </v-dialog>
</template>

<script>
import { ref } from "@vue/composition-api";
import {
  mdiClose,
  mdiCalendar,
  mdiAlertOutline
} from "@mdi/js";
import RequestFormHead from "./RequestFormHead.vue";
import RequestFormBody from "./RequestFormBody.vue";
import RequestFormSidebar from "./RequestFormSidebar.vue";

export default {
  components: {
    RequestFormHead,
    RequestFormBody,
    RequestFormSidebar
},
  data: () => {
    return {
      formdata: {},
      formdataIsValid: false,
      determinations: [],
      profilesModifications: {},
      extraData: {},
      extraDataIsValid: false,
      isEditing: false,
      editingRequest: {},
      showProtocolModal: false,
      sendAnyway: false,
      showAlert: false,
      responseMessage: {title:'', subtitle:''}
    };
  },
  methods: {
    openModal() {
      this.isDialogVisible = true;
    },
    handleItemToEdit(request_data){ //editing from ListRequest
      this.editingRequest = request_data
      this.isDialogVisible = true;
      this.isEditing = true
    },
    updateFormData(formData) {
      this.formdata = {...formData}
    },
    updateDeterminationsData(determinationsData) {
      this.determinations = determinationsData;
    },
    updateExtraData(extraData){
      this.extraData = extraData
    },
    profilesDeterminations(determinationsFromProfiles){
      // console.error(determinationsFromProfiles)
      this.profilesModifications = determinationsFromProfiles
    },
    sendForm() {

      const requestForm = {
        ...this.formdata,
        ...this.extraData,
        determinations: this.determinations,
      }

      if(this.isEditing) {
        this.editRequest(requestForm)
      } else { 
        this.createRequest(requestForm) 
      }
    },
    async createRequest(form){
     
      try {

        const protocolCheckResponse = await this.$api.createRequestSkuCheck(form); 

        if (protocolCheckResponse.data === "El número de protocolo ya está en uso.") {

          // Mostrar el mensaje al usuario y esperar a que seleccione OK o cancelar
          this.responseMessage.title = 'Ya existe el numero de protocolo'
          this.responseMessage.subtitle = `¿desea enviar la solicitud de todas formas? <br> se creará un nuevo SKU para esta solicitud`
          this.showProtocolModal = true

          // Esperar a que el usuario seleccione OK o cancelar
          const confirmed = await new Promise(resolve => {
            this.confirmProtocolModal = () => resolve(true);
            this.cancelProtocolModal = () => resolve(false);
          })

          if (confirmed) {
            // Si el usuario selecciona OK, ejecutar la llamada a createRequest
            const response = await this.$api.createRequest(form)
            this.showAlert = true
            this.responseMessage = {title: 'Se ha creado una nueva solicitud'}
          } else {
            // Si el usuario selecciona cancelar, salir de la función sin hacer nada más
            return this.showProtocolModal = false;
          }
        } 
        
        this.showAlert = true
        this.responseMessage = {title: 'Se ha creado una nueva solicitud'}
        this.$emit('reload-request-list')
                
      } catch (error) {

        this.showAlert = true
        this.responseMessage.title = 'Hubo un error'
      } finally { }
    },
    async editRequest(form) {
      try {
        const editResponse = await this.$api.updateRequest(this.editingRequest.id, form) 
        
        this.responseMessage = { title: 'Solicitud editada correctamente'}

        this.showAlert = true
        this.$emit('reload-request-list')
      } catch (error) {
        this.showAlert = true
        this.responseMessage.title = 'No se pudo editar la solicitud'
      }
    },
    confirmProtocolModal() {
      this.showProtocolModal = false;
      this.confirmProtocolModal = null;
      this.cancelProtocolModal = null;
    },

    cancelProtocolModal() {
      this.confirmProtocolModal = null;
      this.cancelProtocolModal = null;
    },
    resetComponents() {
      this.editingRequest = {}
      this.$refs.request_data_form.reset()
      this.$refs.determination_data_form.reset()
      this.$refs.extra_data_form.reset()
    },
    closeModal(){
      this.showAlert = false
      this.responseMessage.title = '' 
      this.responseMessage.subtitle = ''
      this.isDialogVisible = false
      this.isEditing = false
      this.resetComponents()
    },
    setCompValidation(isValid, componentName) {
      this[`${componentName}IsValid`] = isValid;
    },
  },
  computed: {
    validateAll(){
      return Boolean(!!this.formdataIsValid && !!this.extraDataIsValid)
    }
  },
  setup() {
    const isDialogVisible = ref(false);

    return {
      isDialogVisible,
      icons: {
        mdiClose,
        mdiCalendar,
        mdiAlertOutline
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.toolbar-text{
  color: white !important;
}

.btn1:hover {
  transform: scale(1.20);
  color:red;
}

// .btn2:hover {
//   transform: scale(1.05);
// }
</style>
