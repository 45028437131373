<template>
<v-dialog v-model="showingUserModal" width="800" scrollable persistent minHeight="1200">
  <v-form ref="patientForm" autocomplete="off" @submit.prevent="createPatient">
    <v-card :loading="loading">
      <v-card-title class="text-h5 grey lighten-2">
        {{ editFormId? 'Editar Paciente' : 'Agregar Paciente' }}
      </v-card-title>

      <v-card-text style="max-height: 500px">
        <v-row class="">

          <v-col cols="6" md="6" class="pr-0 mr-n2">
            <v-avatar 
              color="primary"
              class="v-avatar-light-bg primary--text ma-2"
              size="300"
              tile
            >
              <v-img
                v-if="show_pic"
                :src="show_pic"
              ></v-img>
              <span
                v-else
              >{{ '?' }}</span>
            </v-avatar>
          </v-col>

          <v-col cols="6" md="6" class="pl-0">
            <v-col cols="12" md="12" >
            <v-text-field
              ref="name"
              v-model="patient_form.name"
              outlined
              dense
              autocomplete="off"
              hide-details="auto"
              :rules="[() => !!patient_form.name || 'Campo requerido']"
              label="Nombre"
            />
            </v-col>

            <v-col cols="12" md="12" >  
              <v-text-field
                ref="race"
                v-model="patient_form.race"
                outlined
                dense
                autocomplete="off"
                hide-details="auto"
                :rules="[() => !!patient_form.race || 'Campo requerido']"
                label="Raza"
              />
            </v-col>


            <v-col cols="12" md="12">
              <v-menu
                v-model="datePicker"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="computedDateFormatted"
                    label="Fecha de nacimiento"
                    :prepend-icon="icons.mdiCalendar"
                    readonly
                    :hint="`${computedAge ? computedAge.years  : '-'} Año/s y ${computedAge ? computedAge.months : '-'  } Mes/es`"
                    persistent-hint
                    v-bind="attrs"
                    v-on="on"
                    clearable
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="patient_form.birth_date"
                  :first-day-of-week="0"
                  locale="es-es"
                  color="primary"
                  @input="datePicker = false"
                ></v-date-picker>
              </v-menu>
              <!-- <v-card-text>Edad: {{ computedAge ? computedAge.years : '-'}} año/s y {{ computedAge ? computedAge.months : '-' }} mes/es</v-card-text> -->
            </v-col>

            <v-col cols="12" md="12" >
              <v-btn
              outlined 
              color="primary"
              @click="openResizeModal"
              >
                <v-icon class="mr-3">{{ icons.mdiCameraOutline }}</v-icon> 
                <span>{{ show_pic ? 'Cambiar foto' : 'Cargar foto'}}</span>
                
              </v-btn>
            </v-col>
          </v-col>        
          
          <v-col cols="3" md="3" >
            <v-select
              ref="sex"
              v-model="patient_form.sex"
              :items="sexList"
              item-text="name"
              item-value="id"
              outlined
              dense
              autocomplete="off"
              hide-details="auto"
              :rules="[v => (!!v || v === 0) || 'Elija una opción']"
              label="Sexo"
            ></v-select>  
              
          </v-col>

          <v-col cols="3" md="3" >  
            <v-select
              ref="specie"
              v-model="patient_form.specie"
              :items="specieList"
              item-text="name"
              item-value="id"
              outlined
              dense
              autocomplete="off"
              hide-details="auto"
              :rules="[v => (!!v || v === 0) || 'Elija una opción']"
              label="Especie"
            ></v-select>
          </v-col>

          <v-col cols="2" md="2" >  
            <v-text-field
              ref="height"
              type="number"
              v-model="patient_form.height"
              outlined
              dense
              autocomplete="off"
              hide-details="auto"
              hide-spin-buttons
              label="Altura"
              suffix="M"
            />
          </v-col>

          <v-col cols="2" md="2" >  
            <v-text-field
              ref="weight"
              type="number"
              v-model="patient_form.weight"
              outlined
              dense
              autocomplete="off"
              hide-details="auto"
              hide-spin-buttons
              label="Peso"
              suffix="Kg"
            />
          </v-col>

          <v-col cols="6" >
            <template>
              <v-autocomplete
                v-model="patient_form.userdata"
                append-icon=""
                :prepend-inner-icon="icons.mdiMagnify"
                :items="userData"
                :search-input.sync="searchUser"
                outlined
                chips
                deletable-chips
                small-chips
                hide-details
                label="Selecciona un Médico Veterinario"
                item-text="name"
                item-value="id"
                dense
              >
                <template #item="data"> 
                    <v-list-item-content> <v-list-item-title>{{ data.item.name }}</v-list-item-title></v-list-item-content>
                </template>

                <v-list-item 
                  v-show="page_size < userCount"
                  slot="append-item"
                  class="custom-item"
                >
                  <span class="font-italic text-body-2"> Busca para más elementos <v-icon small> {{icons.mdiMagnify}} </v-icon> </span>
                </v-list-item>
                
                <template v-slot:no-data>
                  <v-list-item> <v-list-item-title>No se encontraron<strong>Veterinarios</strong></v-list-item-title> </v-list-item>
                </template>
              </v-autocomplete>
            </template>
          </v-col>

          <v-col class="d-flex" cols="6" >
            <template>
              <v-autocomplete
                v-model="patient_form.tutor"
                append-icon=""
                :prepend-inner-icon="icons.mdiMagnify"
                :items="tutorData"
                :search-input.sync="searchTutor"
                outlined
                chips
                deletable-chips
                small-chips
                hide-details
                label="Selecciona un Tutor"
                item-text="name"
                item-value="id"
                multiple
                dense
              >
              <template #item="data"> 
                  <v-list-item-content> <v-list-item-title>{{ data.item.name }}</v-list-item-title> </v-list-item-content>
              </template>

              <v-list-item 
                v-show="page_size < tutorCount"
                slot="append-item"
                class="custom-item"
              >
                <span class="font-italic text-body-2"> Busca para más elementos <v-icon small> {{icons.mdiMagnify}} </v-icon> </span>
              </v-list-item>

              <template v-slot:no-data>
                <v-list-item> <v-list-item-title> No se encontraron <strong>Tutores</strong> </v-list-item-title> </v-list-item>
              </template>

              </v-autocomplete>
              <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                    v-bind="attrs"
                    v-on="on" 
                    icon 
                    color="primary"
                    @click="$refs.tutors_form_modal.openModal()"
                    > 
                      <v-icon color="primary" v-text="icons.mdiPlusCircleOutline"></v-icon>
                    </v-btn>
                  </template>
                  <span>Crear nuevo</span>
                </v-tooltip>
            </template>
          </v-col>

          <v-col cols="12" >  
            <v-textarea
              ref="extra_data"
              v-model="patient_form.extra_data"
              outlined
              dense
              autocomplete="off"
              hide-details="auto"
              label="Información extra"
            />
          </v-col>
         
        </v-row>
          
      </v-card-text>

      <TutorsFormModal ref="tutors_form_modal"/>

      <!-- actions -->
      <v-col align="right" v-if="errorMessage">
        <span class="caption" style="color:red;">
          {{ errorMessage }}
        </span>
      </v-col>

      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="close()">
            CANCELAR
        </v-btn>
        <v-btn color="primary" type="submit">
          {{ editFormId? 'EDITAR PACIENTE' : 'AGREGAR PACIENTE' }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-dialog
        v-model="showResizeModal"
        width="600"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          <v-icon class="me-2"> {{ icons.mdiImageEditOutline }}</v-icon>
          Edición de imágenes
        </v-card-title>
        
        <v-card-text >
          <!--Cropper api documentation: https://zhanziyang.github.io/vue-croppa/#/ -->
          <v-col cols="12" class="text-center pb-0"> 
            <vue-croppa 
            ref="croppa" 
            :width="450" :height="450" 
            :canvas-color="'default'" 
            :placeholder="`Arrastre su imagen aqui o clickee`" 
            :placeholder-font-size="15" 
            :prevent-white-space="true" 
            >
            <!-- :initial-image="show_pic" -->
            <!-- @new-image="cropImage" -->
            </vue-croppa> 
          </v-col>
       
        </v-card-text>
        <v-card-subtitle class=""> 
          <div @click="$refs.croppa.remove()">
           - Mueve una imagen haciendo click <v-icon small> {{icons.mdiCursorDefaultClick}} </v-icon> y arrastrando <v-icon small> {{icons.mdiArrowAll}} </v-icon>
          </div>
          <div>
           - Scrollea sobre la imagen <v-icon small> {{icons.mdiMouseMoveVertical}} </v-icon> con la rueda del mouse para hacer zoom
          </div>
        </v-card-subtitle>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          
          <v-btn
          color="error"
          outlined
          @click="() => showResizeModal = !showResizeModal"
          >
            CANCELAR
          </v-btn>         
          <v-btn
          color="primary" 
          @click="cropImage"
          >
            CONFIRMAR
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-form>
</v-dialog>
</template>

<script>
import VueCroppa from 'vue-croppa';
import 'vue-croppa/dist/vue-croppa.css';
import { calculateAgeFromDate } from '../../plugins/dateToAgeUtil';
import debounce from "../../plugins/debounce.js";
import { ref, watch, nextTick } from '@vue/composition-api'
import TutorsFormModal from '../Tutors/TutorsFormModal.vue';
import { 
  mdiMagnify,
  mdiClockTimeFourOutline,
  mdiClose,
  mdiEye,
  mdiEyeOff,
  mdiPlus,
  mdiCameraOutline,
  mdiCalendar,
  mdiPlusCircleOutline,
  mdiImageEditOutline,
  mdiMouseMoveVertical,
  mdiCursorDefaultClick,
  mdiArrowAll,
} from '@mdi/js'

export default {
  props:{
    requestUserData: {
      type: Object,
      default: () => {}
    }
  },
  data: () => {
    return {
      page: 1,
      page_size: 10,
      searchUser: '',
      userCount: 0,
      searchTutor: '',
      tutorCount: 0,
      loading: false,

      patient_form: {
        name: "",
        sex: '',
        specie: '',
        race:'',
        birth_date: null,
        weight: 0,
        height: 0,
        extra_data: '',
        tutor:[],
        profile_pic: null,
        files: null,
        userdata: null,
      },
      datePicker: false,
      show_pic: null,
      default_pic: null,
      showResizeModal: false,
      editFormId: null,
      errorMessage: null,

      rules: {
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || false;
        },
      },
    };
  },
  mounted() {
    this.getTutorList()
    this.getVeterinaryUsersList()
  },
  methods: {
    openModal(id_to_edit) {
      // console.log(id_to_edit);
      if(id_to_edit){
        this.setItemToEdit(id_to_edit)
      } else {
         this.showingUserModal = true
         this.patient_form.height = null
         this.patient_form.weight = null
         this.requestUserData ? this.patient_form.userdata = this.requestUserData.id : this.patient_form.userdata = null
      }
    },
    handleUserSearch: debounce(function () {
      this.page = 1;
      this.getVeterinaryUsersList();
    }, 400),
    async getVeterinaryUsersList() {

      try {
        const data = {
          page_size: this.page_size,
          page: this.page,
          summary: true,
          search: this.searchUser,
          role: 0, // Rol Veterinario = 0
        };
        const response = await this.$api.getUsersList(data);
        this.userData = response.results;
        this.userCount = response.count

      } catch (error) {
        // console.log(error);
      }
    },
    handleTutorSearch: debounce(function () {
      this.page = 1;
      this.getTutorList();
    }, 400),
    async getTutorList() {
      
      try {
        const data = {
          page_size: this.page_size,
          page: this.page,
          summary: true,
          search: this.searchTutor,
        };
        const response = await this.$api.getTutorsList(data) //VER EP JAVI
        // console.log(response)
        this.tutorData = response.results
        this.tutorCount = response.count
      } catch (error) {
        console.log(error)
      } 
    },
    formatShowingDate(dates) {
      if (!dates) return null
      const [year, month, day] = dates.split('-')

      return `${day}/${month}/${year}`
    },
    formatDateToSend(dates) {
      if (!dates) return null;
      const [day, month, year] = dates.split('/');
      
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    },
    async setItemToEdit(id_to_edit) {
      const item = await this.$api.getPatient(id_to_edit);
      // console.log(item)

      if (item) {
        this.editFormId = id_to_edit;
        this.patient_form.name = item.data.name ?? null;
        this.patient_form.sex = item.data.sex.id ?? null;
        this.patient_form.race = item.data.race ?? null;
        this.patient_form.specie = item.data.specie.id ?? null;
        this.patient_form.birth_date = item.data.birth_date ?? ""; // birth_date
        this.patient_form.height = item.data.height ?? 0;
        this.patient_form.weight = item.data.weight ?? 0;
        this.patient_form.extra_data = item.data.extra_data ?? '';
        this.patient_form.tutor = item.data.tutor ?? null;
        this.patient_form.userdata = item.data.userdata ?? null;
        this.show_pic = item.data.profile_pic ?? null
        this.default_pic = this.show_pic

        this.showingUserModal = true;
      }
    },
    openResizeModal(){
      this.showResizeModal = true
    },
    cropImage() {
      this.$refs.croppa.generateBlob((blob) => {
        const originalFile = this.$refs.croppa.getChosenFile();
        const modifiedFile = new File([blob], originalFile.name, {
          type: originalFile.type,
          lastModified: originalFile.lastModified,
        });
        this.patient_form.profile_pic = modifiedFile
        
        this.$nextTick(() => {
          this.uploadImage()
          this.showResizeModal = false
        });
      });
    },
    uploadImage(){
      // return console.log(this.patient_form.profile_pic.name)
      if (this.patient_form.profile_pic){
        const file = this.patient_form.profile_pic
        this.show_pic = URL.createObjectURL(file);
      } else {
        this.show_pic = this.default_pic
      }
    },
    async createPatient() {
      if (this.$refs.patientForm.validate()) {
        this.loading = true
        let formData = new FormData();
        
        let rawForm = {
          name: this.patient_form.name,
          sex: this.patient_form.sex,
          specie: this.patient_form.specie,
          race: this.patient_form.race,
          birth_date: this.patient_form.birth_date, // birth_date
          weight: Number(this.patient_form.weight),
          height: Number(this.patient_form.height),
          extra_data: this.patient_form.extra_data,
          userdata: this.patient_form.userdata?? ''
        }

        const keysWithValues = Object.keys(rawForm).filter(key => rawForm[key] !== undefined && rawForm[key] !== null && rawForm[key] !== 0);

        // Crear un nuevo objeto solo con las propiedades que tienen un valor definido
        let form = {};
        keysWithValues.forEach(key => {
          form[key] = rawForm[key];
        });

        // console.log(form)
        if(this.editFormId && this.patient_form.userdata) {
          let editedForm = {...form}
          editedForm.userdata = editedForm.userdata.id?? editedForm.userdata
          form = editedForm
        }

        for(const key in form) {
            formData.set(key, form[key])
        }
        
        if (this.patient_form.profile_pic) {
          formData.set('profile_pic', this.patient_form.profile_pic)
        }
        
        if(this.patient_form.tutor.length) {
          this.patient_form.tutor.forEach (value => formData.append('tutor', value.id ? value.id : value))
        } else { 
          formData.append('tutor', [])
        }

        try {
          const response = this.editFormId
            ? await this.$api.updatePatient(this.editFormId, formData)
            : await this.$api.createPatient(formData);

          this.$emit('display-alert', {type: 'success', message: this.editFormId ? 'Paciente editado correctamente' : 'Se ha creado un nuevo paciente'});
          this.$emit("reload-patients-list");
          this.close();
        }
        catch (err) {
          console.log(err.response); // Corroborar mensajes de error en back
          // this.errorMessage = err.response.data.message
          this.$emit('display-alert', {type: 'error', message:'No se pudo crear el paciente solicitado'});
        } finally {
          this.loading = false
        }
      }
    },
    close() {
      this.resetForm();
      if(this.$refs.croppa){
        this.$refs.croppa.remove()
      }
      this.patient_form.profile_pic = null
      this.showingUserModal = false
    },
    resetForm() {
      this.$refs.patientForm.reset();
      this.show_pic = null;
      this.editFormId = null;
      this.usernameError = null;
      this.localError = null;
    },
    parseForm() {
      const formParsed = JSON.parse(JSON.stringify(this.patient_form));
        
      return formParsed;
    },
  },
  computed: {
    computedDateFormatted: {
      get() { return this.formatShowingDate(this.patient_form.birth_date);},
      set(value) { this.patient_form.birth_date = this.formatDateToSend(value);}
    },
    computedAge() {
      return calculateAgeFromDate(this.patient_form.birth_date);
    },
    getUserRole() { return this.$store.getters["session/getUserRole"](); },
  },
  watch: {
    searchTutor(val) {
      this.handleTutorSearch(val);
    },
    searchUser(val) {
      this.handleUserSearch(val);
    },
    requestUserData:{
        handler:function(newVal) {
          if(newVal){  
            this.patient_form.userdata = newVal.id
          }
        },
        immediate: true
      },
  },
  setup() {
    const showingUserModal = ref(false);

    const userData = ref([])
    const tutorData = ref([])

    const sexList = [
      {id:0, name: "Macho"},
      {id:1, name: "Hembra"},
    ]
    
    const specieList = [
      {id:0, name: "Canino"},
      {id:1, name: "Felino"},
      {id:2, name: "Ovino"},
      {id:3, name: "Bovino"},
      {id:4, name: "Equino"},
      {id:5, name: "Caprino"},
      {id:6, name: "Ave"},
    ]

    return {
      showingUserModal,
      userData,
      tutorData,
      sexList,
      specieList,
      icons: {
        mdiMagnify,
        mdiClose,
        mdiClockTimeFourOutline,
        mdiEye,
        mdiEyeOff,
        mdiPlus,
        mdiCameraOutline,
        mdiCalendar,
        mdiPlusCircleOutline,
        mdiImageEditOutline,
        mdiMouseMoveVertical,
        mdiCursorDefaultClick,
        mdiArrowAll
      },
    };
  },
  components: { TutorsFormModal,  VueCroppa: VueCroppa.component  }
}
</script>

<style scoped>
.custom-item {
  background-color: rgba(151, 76, 232, 0.1) !important;
}

.croppa-container {
  background-color: #f1eaff;
  border: 1px solid black
}
</style>

