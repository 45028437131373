<template>
  <div>
    <v-card>
      <v-divider class="mt-4"></v-divider>

      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <!-- search -->
        <v-text-field
          v-model="search"
          @keyup="handleSearch"
          placeholder="Buscar informe"
          :append-icon="icons.mdiMagnify"
          outlined
          hide-details
          dense
          class="user-search me-3 mb-4"
        >
        </v-text-field>

        <v-spacer></v-spacer>

        <v-select
          v-model="filter.actives"
          style="max-width: 300px"
          class="mb-4"
          :menu-props="{ top: true, offsetY: true }"
          :items="itemsByRole"
          multiple
          hide-details="true"
          label="Agregar filtros"
          item-text="name"
          item-value="id"
          outlined
          dense
        ></v-select>        
        <v-spacer v-if="getUserRole.id === 1 || getUserRole.id === 5"></v-spacer>

        <div v-if="getUserRole.id === 1 || getUserRole.id === 5" class="d-flex align-center flex-wrap">
          <v-btn
            color="primary"
            class="mb-4 me-3"
            @click="$refs.lab_request_form.openModal()"
          >
            <!-- @click="$refs.user_form_modal.openModal()" -->
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span>Nueva Solicitud</span>
          </v-btn>
        </div>
      </v-card-text>

      <v-card-text class="mt-6">

        <v-row>
          <v-col cols="3" sm="3" class="d-flex" v-if="filter.actives.includes(0)">
            <div class="text-center mr-3">
              <v-menu
              v-model="iniDateMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
              left
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="iniDateFormatted"
                  label="Fecha Inicial"
                  readonly
                  dense
                  clearable
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
                  <!-- :prepend-inner-icon="icons.mdiCalendar" -->

              </template>
              <v-date-picker
                v-model="filter.iniDate"
                :first-day-of-week="0"
                locale="es-es"
                color="primary"
                @input="iniDateMenu = false"
                @change="getRequests"
              ></v-date-picker>
            </v-menu>
            </div>

          </v-col>

          <v-col cols="3" sm="3" class="d-flex" v-if="filter.actives.includes(0)">
            <div class="text-center mr-3">
              <v-menu
              v-model="endDateMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
              left
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="endDateFormatted"
                  label="Fecha Final"
                  readonly
                  dense
                  clearable
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
                  <!-- :prepend-inner-icon="icons.mdiCalendar" -->

              </template>
              <v-date-picker
                v-model="filter.endDate"
                :first-day-of-week="0"
                locale="es-es"
                color="primary"
                @input="endDateMenu = false"
                @change="getRequests"
              ></v-date-picker>
            </v-menu>
            </div>

          </v-col>

          <v-col cols="2" sm="2" class="d-flex" v-if="filter.actives.includes(1)">    
            <div class="text-center mr-3">
              <v-select
                v-model="filter.status"
                :items="statusItems"
                item-value="id"
                item-text="name"
                dense
                label="Filtrar por estado"
                @change="getRequests"
              ></v-select>
            </div>

          </v-col>

          <v-col cols="4" sm="4" class="d-flex" v-if="filter.actives.includes(2)">    
            <div class="text-center mr-3">
              <v-select
                v-model="filter.samplingMethod"
                :items="samplingMethods"
                item-value="id"
                item-text="name"
                dense
                label="Filtrar por M. de Muestreo"
                @change="getRequests"
              ></v-select>
            </div>
          </v-col>

        </v-row>
      </v-card-text>

      <!-- table -->
      <v-data-table
        :headers="tableColumns"
        :items="requestsListTable"
        :search="search"
        :page="current_page"
        :server-items-length="totalListTable"
        @update:page="changingRequestsPage($event)"
        @update:items-per-page="changingItemsPerPage($event)"
        :footer-props="{
          'items-per-page-options': [10, 20, 50],
          'items-per-page-text': 'Items por página',
          'page-text': '{0}-{1} de {2}'
        }"
        >

        <!-- NO data -->
        <template v-slot:no-data>
          <v-list-item>
            <v-list-item-title>
              No se encontraron
              <strong>solicitudes</strong>
            </v-list-item-title>
          </v-list-item>
        </template>

        <!-- SKU -->
        <template #[`item.sku`]="{ item }">
          <span class="d-block font-weight-semibold text--primary text-truncate mobile-text-wrap mt-1">{{
            item.sku
          }}</span>
          <small>creado: {{ item.created_at | formatDateTime(getUserRole) }} </small>
          <small class="d-block mb-1" :class="[`${resolveStatusColor(item.status.label)}--text`]"> 
            {{ item.status.label }} 
          </small>
        </template>

        <!-- sampling -->
        <template #[`item.sampling_mode`]="{ item }" v-if="getUserRole.id === 1 || getUserRole.id === 5">
          {{ item.sampling_mode.label }}
        </template>

        <!-- paymentstatus -->
        <template #[`item.payment_status`]="{ item }">
          <v-chip
            small
            :color="item.payment_status === 'Pendiente' ? 'primary' : 'success'"
            :class="item.payment_status === 'Pendiente' ? 'primary--text' :'success--text'"
            class="v-chip-light-bg"
          >
            <!-- :class="`${statusColor[status[item.status]]}--text`" -->
            {{ item.payment_status}}
          </v-chip>
        </template>

        <!-- Veterinario -->
        <template #[`item.userdata`]="{ item }">
          <span 
          class="d-block font-weight-semibold text--primary text-truncate mobile-text-wrap"
          v-if="item.userdata"
          >
            {{ item.userdata.name}}
          </span>
          <span v-else class="d-block">
            {{item.userdata.name}}
          </span>
          <small><b>Vet: </b>  
            <span v-if="item.veterinary_clinic && item.veterinary_clinic.name">{{ item.veterinary_clinic.name }}</span> 
            <span v-else class="font-italic"> sin registrar</span>
          </small>
        </template>

        <!-- Tutor -->
        <template #[`item.tutor`]="{ item }">
          <span v-if="item.tutor && item.tutor.name"
          class="d-block font-weight-semibold text--primary text-truncate mobile-text-wrap">{{
            item.tutor.name
          }}</span>
          <span v-else class="font-italic">sin registrar</span>
        </template>

        <!-- Patient -->
        <template #[`item.patient`]="{ item }">
          <div v-if="item.patient && item.patient.name"> 
            <div class="d-block font-weight-semibold text--primary text-truncate mobile-text-wrap">
            {{  item.patient.name }}
            </div>
            <small> {{ `${item.patient.race}, ${item.patient.sex.label} `}}</small>
          </div>
          <div v-else class="font-italic">sin registrar</div>
        </template>

        <!-- REPORTS -->
        <template #[`item.reports`]="{ item }">
          <v-tooltip top v-if="getUserRole.id === 1 || getUserRole.id === 5" >
            <template v-slot:activator="{ on, attrs }">
              <v-icon 
              v-bind="attrs"
              v-on="on"
              @click="getReportAndOpen({...item})"
              color="primary"
              >
                {{ icons.mdiClipboardEditOutline }}
              </v-icon>
            </template>
            <span>Editar Informe</span>
          </v-tooltip>
          
          <v-menu
            top
            :offset-x="offset"
            transition="scale-transition"
            :close-on-content-click="false"
          >
            <template #activator="{ on: menu, attrs }">
              <v-tooltip bottom>
                <template #activator="{ on: tooltip }">
                  <v-icon
                  v-bind="attrs"
                  v-on="{ ...tooltip, ...menu }"
                  :disabled="item.status.id === 0"
                  :class="downloadIcon"
                  :color="item.files ? 'success' : 'secondary'"
                  >
                    {{ icons.mdiDownload }}
                  </v-icon>
                </template>
                <span>Descargar Informes</span>
              </v-tooltip>
            </template>

            <v-card v-if="item.files">
              <v-card-text v-for="(file, key) in item.files">

                <template v-if="key === 'report'">
                  <div class="mb-1 text-subtitle-2">Informe: </div>
                  <p 
                  class="text-s mb-1 ms-3" v-if="file && file.name" 
                  :class="downloadedFiles.includes(file.name) ? 'opened-link' : '' "
                  @click="downloadReport(file)"
                  > <a>- {{ file.name }}</a></p>
                  <div v-else class="text-s mb-1 ms-3 font-italic"> - sin cargar </div>
                </template>
                
                <template v-if="key === 'files_determinations'">
                  <div class="mb-1 text-subtitle-2"> Determinaciones anexas: </div>
                  <div v-if="file.length">
                    <p 
                    class="text-s mb-1 ms-3" v-for="annex in file" 
                    @click="downloadReport(annex)"
                    :class="downloadedFiles.includes(annex.name) ? 'opened-link' : '' "
                    > <a>- {{annex.name}}</a> </p>
                  </div>
                  <div v-else class="text-s mb-1 ms-3 font-italic"> - sin cargar</div>
                </template>
              
                <template v-if="key === 'general_annexes'">
                  <div class="mb-1 text-subtitle-2"> Anexos generales: </div>
                  <div v-if="file.length">
                    <p 
                    class="text-s mb-1 ms-3"
                    :class="downloadedFiles.includes(annex.name) ? 'opened-link' : '' " 
                    v-for="annex in file" 
                    @click="downloadReport(annex)"
                    > <a>- {{annex.name}}</a> </p>
                  </div>
                  <div v-else class="text-s mb-1 ms-3 font-italic"> - sin cargar </div>
                </template>

              </v-card-text>
            </v-card>
            <v-card v-else class="text-subtitle-2 text-center">
              <v-card-text> No hay archivos disponibles para descargar </v-card-text>
            </v-card>
          </v-menu>

          <v-tooltip top v-if="getUserRole.id === 1 || getUserRole.id === 5">
            <template v-slot:activator="{ on, attrs }">
              <v-icon 
              v-bind="attrs"
              v-on="on"
              :class="downloadIcon"
              @click="$refs.invoices_ref.openModal({invoice_id: item.invoice, request_id: item.id, view_mode: false})"
              color="success"
              >
                {{ icons.mdiCurrencyUsd }}
              </v-icon>
            </template>
            <span>Remito / Pagos</span>
          </v-tooltip>
        </template>

        <template v-if="getUserRole.id === 1 || getUserRole.id === 5" v-slot:header.edit="{ header }">
          {{ header.text }}
          <!-- {{ header.text.toLowerCase() }} -->
        </template>

        <!-- ACTIONS -->
          <template v-if="getUserRole.id === 1 || getUserRole.id === 5" #[`item.edit`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                v-bind="attrs"
                v-on="on"
                @click="$refs.lab_request_form.handleItemToEdit({...item, viewMode: true})"
                color="primary"
                >
                  {{ icons.mdiEyeOutline}}
                </v-icon>
              </template>
              <span>Ver solicitud</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                v-bind="attrs"
                v-on="on"
                :disabled="item.status.label !== 'Pendiente'"
                @click="$refs.lab_request_form.handleItemToEdit({...item, viewMode: false})"
                class="ms-3"
                color="primary"
                >
                  {{ icons.mdiPencilOutline }}
                </v-icon>
              </template>
              <span>Editar solicitud</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                v-bind="attrs"
                v-on="on"
                :disabled="item.status.label == 'Finalizado'"
                @click="deleteModal(item.id, item.sku)"
                class="ms-3"
                color="error"
                >
                  {{ icons.mdiDeleteOutline }}
                </v-icon>
              </template>
              <span>Eliminar solicitud</span>
            </v-tooltip>
        
          </template>

      </v-data-table>

       <!-- DELETE dialog -->
    <v-dialog
      v-model="dialogDelete"
      width="auto"
      max-width="600px"
      >
      <v-card>
        
        <v-card-title>
        Eliminar solicitud: {{ editedItem.sku }} ? 
        </v-card-title>
        <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          outlined
          @click="closeDelete"
        >
          Cancelar
        </v-btn>
        <v-btn
          color="success"
          @click="deleteRequest"
        >
          Eliminar
        </v-btn>
        <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <request-form-modal 
    ref="lab_request_form" 
    @reload-request-list="getRequests"
    />
    <report-form-modal 
    ref="report_modal"
    @refresh-list="getRequests"
    />
    <RegisterPaymentModal 
    ref="invoices_ref"
    @refresh-list="getRequests"
    />

    <AlertPopUp ref="alert_pop_up"/>

    </v-card>
  </div>
</template>

<script>
import debounce from '../../plugins/debounce.js'
import RequestFormModal from "./RequestFormModal.vue";
import ReportFormModal from '@/components/Reports/ReportFormModal.vue'
import RegisterPaymentModal from '../invoices/RegisterPaymentModal.vue';
import AlertPopUp from '@/components/AlertPopUp.vue'
import { 
  mdiMagnify,
  mdiClose,
  mdiEyeOutline,
  mdiPencilOutline,
  mdiClipboardEditOutline,
  mdiDeleteOutline,
  mdiCurrencyUsd,
  mdiDownload
} from "@mdi/js";

export default {
  data: () => {
    return {
      current_page: 1,
      page_size: 10,
      iniDateMenu: false,
      endDateMenu: false,
      filterItems: [
            { name: 'Fechas', id: 0 },
            { name: 'Estado', id: 1 },
            { name: 'M. de muestreo', id: 2 }
          ],
      filter: { actives: [], iniDate: '', endDate: '', status: -1, samplingMethod: -1 },
      search: '',
      totalListTable: 0,
      requestsListTable: [],
      offset: true,
      dialogDelete: false,
      editedItem: {},
      downloadedFiles: []

    };
  },
  filters:{
    formatDateTime(value, userRole){
      const date = value.split(' ')[0].split('-').reverse().join('/');
      const time = value.slice(11, 16);
      return userRole.id === 1 || userRole.id === 5 
        ? `${date} - ${time} hs` 
        : `${date}` 
    },
  },
  mounted() {
    this.getRequests();
  },
  methods: {
    handleSearch: debounce(function() {
      this.current_page = 1;
      this.getRequests();
    }, 400),
    changingRequestsPage(e) {
      this.current_page = e
      this.getRequests()
    },
    changingItemsPerPage(e) {
      this.page_size = e
      this.getRequests()
    },
    formatShowingDate(dates) {
      if (!dates) return null
      const [year, month, day] = dates.split('-')

      return `${day}/${month}/${year}`
    },
    formatDateToSend(dates) {
      if (!dates) return null;
      const [day, month, year] = dates.split('/');
      
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    },
    async getRequests() {

      const data = {
        page_size: this.page_size,
        page: this.current_page,
        search: this.search,
        summary: false,
        status: this.filter.status,
        since: this.filter.iniDate,
        to: this.filter.endDate,
        sampling_mode: this.filter.samplingMethod
      }
      try {
        const response = await this.$api.getRequestList(data);
        this.requestsListTable = response.results;
        this.totalListTable = response.count
      } catch (error) {
        console.log(error)
      }
      
    },
		async getReportAndOpen(item) {
      try {
				
        const data = {
          reportId: item.report,
          request: item,
					viewMode: item.viewMode ? true : null
        }
        this.$refs.report_modal.openReportModal(data)
      } catch(e) {
        console.log(e)
      }
    },
    downloadReport(file){

      window.open(file.url, '_blank')
      this.downloadedFiles.push(file.name)
      
    },
    deleteModal(item_id, item_sku) {
      this.editedItem = {id:item_id, sku: item_sku}
      this.dialogDelete = true
    },
    async deleteRequest() { 
      try {
        const response = await this.$api.deleteRequest(this.editedItem.id)
        this.showAlert = true
        this.$refs.alert_pop_up.displayAlert({ message: 'Solicitud eliminada correctamente', type: 'success' })
        this.getRequests()
      } catch (e) {
        this.$refs.alert_pop_up.displayAlert({ message: 'No se ha podido eliminar esta soicitud', type: 'error' })
        console.log(e)
      }
      this.closeDelete()
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = {}
      })
    },
  },computed: {
    getUserRole() { return this.$store.getters['session/getUserRole']() },
    downloadIcon() { return this.getUserRole.id === 1 || this.getUserRole.id === 5 ? 'ms-5' : ''},
    itemsByRole() {
      if(this.getUserRole.id === 1 || this.getUserRole.id === 5) {
        return this.filterItems
      } else {
        return this.filterItems.filter(item => item.id !== 2);
      }
    },
    tableColumns() {
      let tableColumns = [
        { text: "INFORME", value: "sku", align: "left", sortable: false },
        { text: "M. DE MUESTREO", value: "sampling_mode", align: "left", sortable: false },
        { text: "INFORME", value: "reports", align: "center", sortable: false, width: "150px" },
        { text: "ESTADO DE PAGO", value: "payment_status", align: "center", sortable: false },
        { text: "VETERINARIO", value: "userdata", align: "left", sortable: false },
        { text: "TUTOR", value: "tutor", align: "left", sortable: false },
        { text: "- ACCIONES -", value: "edit", align: "center", sortable: false, width: "130px" }
      ]
      if (this.getUserRole.id === 1 || this.getUserRole.id === 5) {
        return tableColumns; // Devolver la lista completa
      } else {
        tableColumns.splice(1, 1); // Eliminar el segundo elemento
        tableColumns.splice(3, 1); // Eliminar el 3er elemento
        tableColumns.pop(); // Eliminar el último elemento
        tableColumns.push({ text: "PACIENTE", value: "patient", align: "left", sortable: false },)
        return tableColumns;
      }
    },
    iniDateFormatted: {
      get() { return this.formatShowingDate(this.filter.iniDate);},
      set(value) { this.filter.iniDate = this.formatDateToSend(value);}
    },
    endDateFormatted: {
      get() { return this.formatShowingDate(this.filter.endDate);},
      set(value) { this.filter.endDate = this.formatDateToSend(value);}
    },
  },
  watch: {
    filter:{ //watcher to enable/disable filters
      handler: function(newVal) {
        if (!newVal.actives.includes(1)) {
          this.filter.status = -1
          this.getRequests()
        } 

        if (!newVal.actives.includes(0)) {
          this.filter.iniDate = ''
          this.filter.endDate = ''
          this.getRequests()
        }
        
        if (!newVal.actives.includes(2)) {
          this.filter.samplingMethod = -1
          this.getRequests()
        } 
      },
      deep: true
    }
  },
  setup() {
    const statusItems =  [
      { name: '-', id: -1 },
      { name: 'Pendiente', id: 0 },
      { name: 'Procesado', id: 1 },
      { name: 'Finalizado', id: 2 },
    ]

    const samplingMethods =  [
      {id: -1 , name: '-'},
      {id: 0, name:"Toma de muestra en laboratorio"},
      {id:1, name: "Toma de muestra en domicilio"},
      {id:2, name:"Retiro de muestra por veterinaria"},
      {id:3, name:"Otro"}
    ]

    const resolveStatusColor = status => {
      if (status === 'Pendiente') return 'primary'
      if (status === 'Procesando') return 'accent'
      if (status === 'Finalizado') return 'success'
      
      else return 'secondary'

    }

    return {
      statusItems,
      samplingMethods,
      resolveStatusColor,
      // icons
      icons: {
        mdiClose,
        mdiPencilOutline,
        mdiEyeOutline,
        mdiCurrencyUsd,
        mdiMagnify,
        mdiClipboardEditOutline,
        mdiDeleteOutline,
        mdiDownload
      },
    };
  },
  components: {
    RequestFormModal,
    ReportFormModal,
    AlertPopUp,
    RegisterPaymentModal
},
};
</script>

<style scoped>
.no-uppercase {
  text-transform: unset !important;
}

.opened-link a {
  color: #563398 !important;
}

@media (max-width: 600px) {
  .mobile-text-wrap {
    white-space: wrap !important;
  }
}
</style>
