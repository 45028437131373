<template>
  <v-dialog 
    class="hide-scroll" 
    v-model="showingInvoiceModal" 
    persistent
    fullscreen
  >
    <!-- max-width="1000" -->
    <!-- fullscreen hide-overlay -->
    <v-card class="modal-background hide-scroll">
    
      <v-app-bar elevation="3" class="tool-bar invoice-modal-header">
        <v-toolbar-title class="text-h5 grey lighten-2" >Solicitud: {{ request_sku }}
        </v-toolbar-title>
        
        <v-chip
          class="ml-4"
          :color="status_color"
          small
        >
          {{ status_invoice.status || 'Sin definir' }}
        </v-chip>

        <v-btn icon color="primary" class="close-btn" @click="close()">
          
        <v-icon>{{ icons.mdiClose }}</v-icon>
        </v-btn>
      </v-app-bar>
      <!-- <v-divider class="mt-n2"></v-divider> -->
      <v-row class="invoice-body">

        <v-col 
          class="mt-3" 
          cols="12"
        >
          <v-card style="margin: 0.5rem">
            <v-card-title class="pt-5"> Datos del Cliente</v-card-title>

            <div class="text-center mt-10 mb-10" v-if="loading_client">
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </div>


            <v-container v-else class="px-4">

              <v-row v-if="Object.keys(request_data_parsed.patient).length">
                <v-col cols="12" sm="6">
                  <v-card-text>
                    <v-row>
                      <strong class="me-2">Paciente: </strong> {{ request_data_parsed.patient.name }}
                    </v-row>
                    <v-row>
                      <strong class="me-2">Especie: </strong> {{ request_data_parsed.patient.specie }}
                    </v-row>
                    <v-row>
                      <strong class="me-2">Edad: </strong> {{ request_data_parsed.patient.age }}
                    </v-row>
                    <v-row>
                      <strong class="me-2">Tutor: </strong> {{ request_data_parsed.tutor? request_data_parsed.tutor : 'Sin tutor' }}
                    </v-row>
                  </v-card-text>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-card-text>
                    <v-row>
                      <strong class="me-2">Veterinario clínico: </strong> {{ request_data_parsed.vet ? request_data_parsed.vet : 'Sin veterinario asignado' }}
                    </v-row>
                    <v-row>
                      <strong class="me-2">Clínica veterinaria: </strong> {{ request_data_parsed.veterinary_clinic ? request_data_parsed.veterinary_clinic : 'Sin clínica asignada'  }}
                    </v-row>
                  </v-card-text>
                </v-col>

              </v-row>

              <v-row v-else>
                <v-col cols="12" sm="6">
                  <v-card-text>
                    <v-row>
                      <strong class="me-2">Nombre</strong> {{ requestless_clients.name}}
                    </v-row>
                  </v-card-text>
                </v-col>
              </v-row>

            </v-container>

            <v-divider class="ms-3 me-3"></v-divider>

            <v-card-title class="pt-5"> Datos del Remito</v-card-title>
            <!-- <v-divider class="ms-3 me-3"></v-divider> -->

            
            <v-form  ref="payment_form">

              <v-container v-if="loading">
                <div class="text-center mt-20 mb-20" >
                  <v-progress-circular
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </div>
              </v-container>

              <v-container v-else>
                <v-row class="px-4">
                  <v-col cols="12">
                    <v-simple-table dense>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">
                              REFERENCIA
                            </th>
                            <th class="text-left">
                              VALOR

                              <v-icon v-if="invoice.status && invoice.status.id == 0"
                               class="ms-3"
                               color="primary" 
                               @click="enableEditing(invoice.id)"
                              >
                                {{ icons.mdiPencilOutline }}
                              </v-icon>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr

                          >
                            <td>{{ invoice_items.reference }}</td>
                            <td>$ 
                              {{ invoice_items.price }}
                              
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-col>
                </v-row>
                                  
                <v-divider class="my-6"></v-divider>

                <v-row class="px-4" v-if="!viewMode">
                  <v-col 
                    cols="12" 
                    sm="6"
                  >                      
                    <v-autocomplete 
                      v-model="payment_method"
                      :items="payment_methods"
                      item-text="label"
                      item-value="id"
                      outlined
                      dense
                      label="Método de Pago"
                      :rules="[value => typeof value == 'number' || 'Este campo es requerido.']"
                    ></v-autocomplete>
                  </v-col>


                  <v-col cols="12" sm="6">                      
                    <v-file-input
                      ref="attached_file"
                      show-size
                      label="Adjuntar archivo"
                      dense
                      hide-details
                      outlined
                      v-model="attached_file"
                    ></v-file-input>
                  </v-col>
                </v-row>

                <v-row class="px-4" v-if="!viewMode">
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <v-text-field
                      label="Observaciones"
                      dense
                      outlined
                      hide-details="auto"
                      counter
                      maxlength="100"
                      v-model="observations"
                    />
                  </v-col>
                </v-row>

              </v-container>
            </v-form>

          </v-card>
        </v-col>

      </v-row>
      
      <v-app-bar tile elevation="5" class="tool-bar invoice-modal-footer">
        <v-spacer></v-spacer>
        
        <v-card-actions class="pa-3" >

          <span class="text-2xl font-weight-medium mr-4" style="color: #555">
            TOTAL: ${{ total_invoice }}
          </span>

          <v-btn
            color="primary"
            class="mb-3 mt-3 me-3"
            @click="showCreatePayment()"
            v-if="!viewMode"
          >
            Registrar Pago <v-icon class="ml-2">{{ icons.mdiCheck }}</v-icon>
          </v-btn>

        </v-card-actions>

      </v-app-bar>
    </v-card>
    <!-- <alert-pop-up ref="alert_pop_up"/> -->

    <v-dialog
      v-model="showingFinishPaymentModal"
      width="auto"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          <!-- ¿Estás seguro de finalizar el informe? -->
          ¿Registrar el pago?
        </v-card-title>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          
          <v-btn
            color="error"
            outlined
            @click="() => showingFinishPaymentModal = !showingFinishPaymentModal"
          >
            CANCELAR
          </v-btn>         
          <v-btn
            color="success" 
            @click="createPayment()"
          >
            Registrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <InvoiceEditModal 
    ref="invoice_edit_modal"
    @refresh-list="callGetOneInvoice(invoice.id)" 
    />

  </v-dialog>
</template>

<script>
// import AlertPopUp from '@/components/AlertPopUp.vue';
import { 
  mdiClose,
  mdiCheck,
  mdiArrowLeftDropCircleOutline,
  mdiCurrencyUsd,
  mdiSendOutline,
  mdiEyeOutline,
  mdiPencilOutline,
  mdiCogOutline,
  mdiDeleteOutline,
} from '@mdi/js'
import InvoiceEditModal from './InvoiceEditModal.vue'

export default {
  data: () => {
    return {
      
      statusList: [{ id: 0, label: 'Pendiente' }, { id: 1, label: 'Finalizado' },],
      
      loading: false,
      showingInvoiceModal: false,
      showingFinishPaymentModal: false,
      request_sku: '',

      payment_method: null,
      attached_file: null,
      observations: "",

      loading_client: false,
      request_data_parsed: { patient: {}, vet: null, veterinary_clinic: null, tutor: null },
      requestless_clients: {},
      invoice: {},
      invoice_items: [],

      viewMode: false,

    }
  },
  methods: {
    openModal(data) {

      this.viewMode = !!data.view_mode
      this.callGetOneInvoice(data.invoice_id)

      //request related clients
      if(data.type === 'request') this.callGetOneRequest(data.client_id)
      //Other invoices non request related
      if(data.type === 'userdata') this.getUserClient(data.client_id)
      if(data.type === 'tutor') this.getTutorClient(data.client_id)
      if(data.type === 'other') this.requestless_clients = {name: data.client_name}
      
    },

    async callGetOneInvoice(id) {
      this.showingInvoiceModal = true
      this.loading = true
      try {
        const response = await this.$api.getOneInvoice(id)
        this.request_sku = response.request?.sku

        this.invoice = response
        this.invoice_items = response.items
      } catch(e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },

    async callGetOneRequest(id) {
      this.loading_client = true
      try {
        const response = await this.$api.getRequest(id)

        const requestData = {
          tutor: response.data.tutor?.name,
          vet: response.data.userdata?.name,
          veterinary_clinic: response.data.veterinary_clinic?.name,
          patient: {
            name: response.data.patient?.name,
            age: response.data.patient?.age,
            specie: response.data.patient?.specie?.label,
          }
        }

        this.request_data_parsed = requestData
        
      } catch(e) {
        console.log(e)
      } finally {
        this.loading_client = false
      }
    },

    async getUserClient(id) {
      this.loading_client = true
      try {
        const response = await this.$api.getUser(id)
        this.requestless_clients = response.data
      } catch(e) {
        console.log(e)
      } finally {
        this.loading_client = false
      }
    },

    async getTutorClient(id) {
      this.loading_client = true
      try {
        const response = await this.$api.getTutor(id)
        this.requestless_clients = response.data
      } catch(e) {
        console.log(e)
      } finally {
        this.loading_client = false
      }
    },

    showCreatePayment() {
      if (this.$refs.payment_form.validate()) this.showingFinishPaymentModal = !this.showingFinishPaymentModal
    },

    async createPayment() {
      const form = new FormData()

      const invoice_list = JSON.stringify(this.invoice_items)

      form.set('invoice', this.invoice.id)
      form.set('payment_method', this.payment_method)
      form.set('reference', this.observations)
      if (this.attached_file) form.set('files', this.attached_file)
      form.set('amount', this.total_invoice)
      form.set('items', invoice_list)

      // return
      if (this.$refs.payment_form.validate()) {
        try {
          /* const response =  */await this.$api.createPayment(form)
          // console.log(response);

          this.$emit('show-alert', { message: 'Pago creado correctamente', type: 'success' })
          this.$emit('refresh-list')
          this.close()
        } catch (error) {
          this.$emit('show-alert', { message: 'No se pudo registrar, asegurese de que el veterinario y/o el paciente asociados no hayan sido eliminados de los registros', type: 'error' })
          console.log(error);
        }
      }

    },

    enableEditing(id) {
      this.$refs.invoice_edit_modal.openModal(id)
    },

    close() {
      this.showingInvoiceModal = false
      this.payment_method = null
      this.attached_file = null
      this.observations = ""
      this.request_data_parsed = { patient: {}, vet: null, veterinary_clinic: null, tutor: null }
      this.requestless_clients = {}
    },
    
    validateAllInputs() {
      let validToSend = true
      for (const key in this.form) {
        if(this.form[key] === '') {
          validToSend = false
          
        }
      }
      return validToSend
    },
    
    
  },
  computed: {
    payment_methods() {
      return [
          { id: 0, label: 'Efectivo' }, 
          { id: 1, label: 'Transferencia' },
          { id: 2, label: 'Tarjeta de Crédito' },
          { id: 3, label: 'Tarjeta de Débito' },
          { id: 4, label: 'Mercado Pago'}
        ]
    }, 
    status_invoice() { return this.invoice.status ?? {} },
    status_color() {
      const status =  this.invoice?.status?.status
      return status
        ? status === 'Pendiente'
          ? '#6D8CD4'
          : '#75B67C'
        : '#778899'
    },

    total_invoice() {
      return this.invoice_items.price
      // return this.invoice_items.reduce((accumulator, current) => Number(accumulator) + Number(current.price), 0)
    },
  },
  components: {
    InvoiceEditModal
},
  setup() {
    return {
      icons: {
        mdiClose,
        mdiCheck,
        mdiArrowLeftDropCircleOutline,
        mdiCurrencyUsd,
        mdiSendOutline,
        mdiEyeOutline,
        mdiPencilOutline,
        mdiCogOutline,
        mdiDeleteOutline,
      }
    }
  }
}
</script>

<style scoped>
.hide-scroll{
  overflow-y: hidden !important;
  overflow-x: hidden;
}

.modal-background {
  background-color: #f4f5fa;
  overflow-y: hidden;
  overflow-x: hidden;
}

.loading-circle {
  position: fixed;
  left: 50%;
  top: 3%;
  z-index: 10;
}

.close-btn {
  position: absolute; 
  right: 0; 
  padding: 1rem !important; 
  margin: 0.8rem !important
}

.invoice-modal-header {
  position: fixed;
  z-index: 1;
}

.invoice-modal-footer {
  position: fixed;
  bottom: 0;
  z-index: 1;
}

.invoice-body {
  margin: 4rem auto; 
  max-width: 1000px;
}
</style>