import { render, staticRenderFns } from "./SendReportModal.vue?vue&type=template&id=94bc42d6&scoped=true&"
import script from "./SendReportModal.vue?vue&type=script&lang=js&"
export * from "./SendReportModal.vue?vue&type=script&lang=js&"
import style0 from "./SendReportModal.vue?vue&type=style&index=0&id=94bc42d6&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "94bc42d6",
  null
  
)

export default component.exports