<template>
<v-dialog v-model="showingUserModal" width="800" persistent minHeight="1200">
  <v-form ref="tutorForm" autocomplete="off" @submit.prevent="createTutor">
    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        {{ editFormId? 'Editar Tutor' : 'Agregar Tutor' }}{{ requestPatient ? ` > Paciente: ${requestPatient.name}` : '' }}
      </v-card-title>

      <v-card-text style="max-height: 500px">
        <v-row class="mt-2">
          
          <v-col cols="6" md="6" >  
            <v-text-field
              ref="name"
              v-model="tutor_form.name"
              outlined
              dense
              autocomplete="off"
              hide-details="auto"
              :rules="[() => !!tutor_form.name || 'Campo requerido']"
              label="Nombre del Tutor"
            />
          </v-col>

          <v-col cols="6" md="6" >  
            <v-text-field
              ref="address"
              v-model="tutor_form.address"
              outlined
              dense
              autocomplete="off"
              hide-details="auto"
              label="Dirección"
            />
          </v-col>

          <v-col cols="6" md="6" >  
            <v-text-field
              ref="phone"
              v-model="tutor_form.phone"
              outlined
              dense
              autocomplete="off"
              hide-details="auto"
              label="Teléfono"
            />
          </v-col>

          <v-col cols="6" md="6" >  
            <v-text-field
              ref="email"
              v-model="tutor_form.email"
              outlined
              dense
              autocomplete="off"
              hide-details="auto"
              label="Email"
            />
          </v-col>

          <v-col cols="6" >
            <template v-if="!requestPatient">
              <v-autocomplete
                v-model="tutor_form.patients"
                append-icon=""
                :prepend-inner-icon="icons.mdiMagnify"
                :items="patientsData"
                :search-input.sync="searchPatient"
                outlined
                chips
                deletable-chips
                small-chips
                hide-details
                label="Selecciona un Paciente"
                item-text="name"
                item-value="id"
                multiple
                dense
              >
                <template #item="data"> 
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ data.item.name }}
                      </v-list-item-title>
                    </v-list-item-content>
                </template>

                <v-list-item 
                  v-show="page_size < patientsCount"
                  slot="append-item"
                  class="custom-item"
                >
                  <span class="font-italic text-body-2"> Busca para más elementos <v-icon small> {{icons.mdiMagnify}} </v-icon> </span>
                </v-list-item>
                
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title>
                      No se encontraron
                      <strong>Pacientes</strong>
                    </v-list-item-title>
                  </v-list-item>
                </template>
              </v-autocomplete>
            </template>
          </v-col>

        </v-row>
          
      </v-card-text>

      <!-- actions -->

      <v-col align="right" v-if="errorMessage">
        <span class="caption" style="color:red;">
          {{ errorMessage }}
        </span>
      </v-col>

      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="close()">
            CANCELAR
        </v-btn>
        <v-btn color="primary" type="submit">
          {{ editFormId? 'EDITAR TUTOR' : 'AGREGAR TUTOR' }}
        </v-btn>
      </v-card-actions>

    </v-card>
  </v-form>


</v-dialog>
</template>

<script>
import debounce from "../../plugins/debounce.js";
import { 
  mdiClockTimeFourOutline,
  mdiClose,
  mdiEye,
  mdiEyeOff,
  mdiPlus,
  mdiMagnify
} from '@mdi/js'

export default {
  props:{
    requestPatient: {
      type: Object,
      default: () => {}
    }
  },
  data: () => {
    return {
      showingUserModal: false,
      patientsData: [],

      page: 1,
      page_size: 10,
      searchPatient: '',
      patientsCount: 0,
      
      tutor_form: {
        name: "",
        address: "",
        phone: "",
        email: "",
        has_patients: false,
        patients: []
      },
      editFormId: null,      
      errorMessage: '',

      rules: {
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || false;
        },
      },
    };
  },
  mounted() {
    this.getPatientsList()
  },
  methods: {
    handlePatientSearch: debounce(function () {
      this.page = 1;
      this.getPatientsList();
    }, 400),
    openModal(id_to_edit) {
      // console.log(id_to_edit);
      id_to_edit
        ? this.setItemToEdit(id_to_edit)
        : this.showingUserModal = true;
    },
    async getPatientsList() {
      const data = {
          page_size: this.page_size,
          page: this.page,
          search: this.searchPatient,
          summary: true,
        };
      try {
        const response = await this.$api.getPatientsList(data) 
        // console.log(response)
        this.patientsData = response.results
        this.patientsCount = response.count
      } catch (error) {
          console.log(error)
      } 
    },
    async setItemToEdit(id_to_edit) {
      const item = await this.$api.getTutor(id_to_edit);
 
      if (item) {
        this.editFormId = id_to_edit;
        this.tutor_form.name = item.data.name ?? null;
        this.tutor_form.address = item.data.address ?? null;
        this.tutor_form.phone = item.data.phone ?? null;
        this.tutor_form.email = item.data.email ?? null;
        this.tutor_form.patients = item.data.patients?? null;
     
        this.showingUserModal = true;
      }
    },
    async createTutor() {
      if (this.$refs.tutorForm.validate()) {

        if (this.tutor_form.patients.length >= 1) {
          this.tutor_form.has_patients = true
        } 
        
        let form = this.parseForm();

        if(this.editFormId) {
          const editedForm = {...form}
          editedForm.patients = editedForm.patients.map(el => el.id ? el.id : el)
          form = editedForm
        }
        
        // console.log(form); 
        try {

          const response = this.editFormId
          ? await this.$api.updateTutor(this.editFormId, form)
          : await this.$api.createTutor(form);
          
          // console.log(response)
          this.$emit('display-alert', {type: 'success', message: this.editFormId ? 'Tutor editado correctamente' : 'Se ha creado un nuevo tutor'});
          this.$emit("reload-tutors-list");
        }
        catch (err) { 
          console.log(err.response);
          this.$emit('display-alert', {type: 'error', message:'No se pudo crear el tutor solicitado'});
   
        }
        this.close(this.alertTimeout);
      }
    },
    close(timeout) {
      setTimeout(() => {
        this.resetForm();
        this.showingUserModal = false;
      }, timeout);
    },
    resetForm() {
      this.tutor_form.address = ''
      this.tutor_form.email = ''
      this.tutor_form.name = ''
      this.tutor_form.phone = ''
      this.tutor_form.patients = []
      this.editFormId = null;
      this.responseMessage = null;
      this.errorMessage = null;
    },
    parseForm() {
      const formParsed = JSON.parse(JSON.stringify(this.tutor_form));
        
      return formParsed;
    },

    },
    computed: {
      getUserRole() { return this.$store.getters["session/getUserRole"](); },
      tutorHasPatient() { return this.tutor_form.patients}
    },
    watch:{
      searchPatient(val) {
        this.handlePatientSearch(val);
      },
      tutorHasPatient(newVal) {
        this.tutor_form.has_patients = newVal.length >= 1 ? true : false 
      },
      requestPatient:{
        handler:function(newVal) {
          if(newVal){  
            // console.log(newVal.id);
            this.tutor_form.name = ''
            this.tutor_form.address = ''
            this.tutor_form.phone = ''
            this.tutor_form.email = ''
            this.tutor_form.patients = [newVal.id]
          }
        },
        immediate: true
      },
    },
    setup() {

      return {
        icons: {
          mdiClose,
          mdiClockTimeFourOutline,
          mdiEye,
          mdiEyeOff,
          mdiPlus,
          mdiMagnify
        },
      };
    },  
    components: { }
}
</script>
<style lang="scss" scoped>
.custom-item {
  background-color: rgba(151, 76, 232, 0.1) !important;
}
</style>

