<template>
  <div class="ms-2">
     
    <!-- <v-card class="text-center" style="padding-top: 110px " >
    <img src="../../assets/logos/PNG/Recurso 1.png" alt=""
      class="img-fluid mx-auto"
      width="500"
      style="max-width: 100%"
    >
    </v-card> -->
    <v-form ref="extraDataForm" style="padding-top: 60px">
      
      <v-card >
        <v-row no-gutters class="pa-6 pb-0">
          <v-col cols="12" class="mb-4">
            <v-col cols="6" class="mx-auto">
              <v-text-field
                ref="code"
                type="number"
                v-model="protocol_number"
                :prepend-inner-icon="icons.mdiPoundBoxOutline"
                outlined
                dense
                autocomplete="off"
                :rules="[v => (!!v && v !== 0) || 'Protocolo requerido']"
                hide-details="auto"
                :readonly="initialData.viewMode"
                :disabled="this.initialData && Object.keys(this.initialData).length > 0 && !initialData.viewMode"
                label="Número de protocolo"
                @input="updateExtraData"
              />
            </v-col>
          </v-col> 
          <v-col cols="6" md="6" sm="12" class="pe-8 ps-0">
            <v-menu
              v-model="menu3"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
              :disabled="initialData.viewMode"
              left
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="computedDateFormatted3"
                  label="Toma de muestra"
                  :prepend-inner-icon="icons.mdiCalendar"
                  readonly
                  outlined
                  dense 
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="sampling_date"
                :first-day-of-week="0"
                locale="es-es"
                color="primary"
                @input="menu3 = false"
                @change="updateExtraData"
              ></v-date-picker>
            </v-menu>
          </v-col>

          <v-col cols="6" md="6" sm="12" class="pe-8 ps-0">
            <v-menu
              v-model="menu2"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
              :disabled="initialData.viewMode"
              left
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="computedDateFormatted2"
                  label="Fecha estimada de reporte"
                  :prepend-inner-icon="icons.mdiCalendar"
                  readonly
                  outlined
                  dense
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="estimated_reporting_date"
                :first-day-of-week="0"
                :allowed-dates="disablePastDays"
                locale="es-es"
                color="primary"
                @input="menu2 = false"
                @change="updateExtraData"
              ></v-date-picker>
            </v-menu>
          </v-col>

          
          <v-col cols="6" class="mb-4">
            <v-select
              ref="muestreo"
              v-model="sampling_mode"
              :prepend-inner-icon="icons.mdiFlask"
              :items="samplingMethods"
              item-text="name"
              item-value="id"
              outlined
              dense
              autocomplete="off"
              hide-details="auto"
              :rules="[v => (!!v || v === 0) || 'Debe seleccionar un método de muestreo']"
              :readonly="initialData.viewMode"
              label="Método de muestreo"
              @input="updateExtraData"
            />
          </v-col>

          <v-col cols="4" class="mb-4 ms-auto" v-if="!Object.keys(initialData).length">
            <v-text-field
              ref="price"
              type="number"
              v-model="sm_price"
              :prepend-inner-icon="icons.mdiCurrencyUsd"
              outlined
              dense
              autocomplete="off"
              hide-details="auto"
              :readonly="initialData.viewMode"
              label="Precio"
              @input="updateExtraData"
            />
          </v-col>

          <v-col cols="12" class="mb-4">  
              <v-textarea
                ref="presumptive_diagnosis"
                v-model="presumptive_diagnosis"
                :prepend-inner-icon="icons.mdiPencilPlusOutline"
                outlined
                dense
                rows="1"
                autocomplete="off"
                hide-details="auto"
                :readonly="initialData.viewMode"
                label="Diagnóstico presuntivo"
                @input="updateExtraData"
              />
          </v-col>

          <v-col cols="12" class="mb-4">  
              <v-textarea
                ref="other_determinations"
                v-model="other_determinations"
                :prepend-inner-icon="icons.mdiPencilPlusOutline"
                outlined
                dense
                rows="1"
                autocomplete="off"
                hide-details="auto"
                :readonly="initialData.viewMode"
                label="Otras determinaciones"
                @input="updateExtraData"
              />
          </v-col>
          <v-spacer cols="2"></v-spacer>
        </v-row>
      
        <div class="px-7 pb-6">
          <v-card-title> Perfiles </v-card-title>

          <v-expansion-panels multiple :value="panel" focusable>
            
            <v-expansion-panel>
              <v-expansion-panel-header>
                <v-card-title class="justify-center">
                  Pequeños Animales
                </v-card-title>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-card-text>
                  <v-row dense no-gutters>
                    <v-col v-for="profile in profilesList" :key="profile.name" 
                    v-if="profile.type === 'general'"
                    class="d-flex v-list-item" 
                    cols="6" lg="6" md="12" sm="12"
                    >
                      <v-checkbox
                      dense 
                      v-model="profile.checked" 
                      @click="selectProfile(profile)"
                      :disabled="initialData.viewMode"
                      ></v-checkbox>
                      <v-card-title class="caption px-0 py-2 mb-2">
                        <v-tooltip 
                        right
                        open-delay="100"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <span 
                            style="cursor:help"
                            v-on="on" 
                            v-bind="attrs"
                            >
                              {{ profile.title }}  
                            </span>
                          </template>
                          <div v-for="determination in profile.determinations">
                            {{ determination.label }}
                          </div>
                        </v-tooltip>
                      </v-card-title>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-expansion-panel-content>
            </v-expansion-panel> 
         
            <v-expansion-panel>
              <v-expansion-panel-header>
                <v-card-title class="justify-center">
                  PCR
                </v-card-title>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-card-text>
                    <v-row dense no-gutters>
                      <v-col v-for="profile in profilesList" :key="profile.name" 
                      v-if="profile.type === 'pcr_caninos_felinos' || profile.type === 'pcr_bovinos' || profile.type === 'pcr_equinos' || profile.type === 'pcr_aves'"
                      class="d-flex v-list-item" 
                      cols="6" lg="6" md="12" sm="12"
                      >
                        <v-checkbox 
                        v-model="profile.checked" 
                        dense
                        @click="selectProfile(profile)"
                        :disabled="initialData.viewMode"
                        ></v-checkbox>
                        <v-card-title class="caption px-0 py-2 mb-2">
                          <v-tooltip 
                          right
                          open-delay="100"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <span 
                              style="cursor:help"
                              v-on="on" 
                              v-bind="attrs"
                              >
                                {{ profile.title }}  
                              </span>
                            </template>
                            <div v-for="determination in profile.determinations">
                              {{ determination.label }}
                            </div>
                          </v-tooltip>
                        </v-card-title>
                      </v-col>
                    </v-row>
                </v-card-text>
              </v-expansion-panel-content>
            </v-expansion-panel>  

            <v-expansion-panel>
              <v-expansion-panel-header>
                <v-card-title class="justify-center">
                  Agentes Infecciosos
                </v-card-title>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-card-text>
                    <v-row dense no-gutters>
                      <v-col v-for="profile in profilesList" :key="profile.name" 
                      v-if="profile.type === 'agentes_infecciosos_parasitarios'"
                      class="d-flex v-list-item" 
                      cols="6" lg="6" md="12" sm="12"
                      >
                        <v-checkbox 
                        v-model="profile.checked" 
                        dense
                        @click="selectProfile(profile)"
                        :disabled="initialData.viewMode"
                        ></v-checkbox>
                        <v-card-title class="caption px-0 py-2 mb-2">
                          <v-tooltip
                          right 
                          open-delay="100"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <span 
                              style="cursor:help"
                              v-on="on" 
                              v-bind="attrs"
                              >
                                {{ profile.title }}  
                              </span>
                            </template>
                            <div v-for="determination in profile.determinations">
                              {{ determination.label }}
                            </div>
                          </v-tooltip>
                        </v-card-title>
                      </v-col>
                    </v-row>
                </v-card-text>
              </v-expansion-panel-content>
            </v-expansion-panel>
          
        </v-expansion-panels>
      </div>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import profiles from '@/utils/profiles'
import AppCardActions from "@core/components/app-card-actions/AppCardActions.vue";
import { 
  mdiCalendar,
  mdiFlask,
  mdiPencilPlusOutline,
  mdiPoundBoxOutline,
  mdiCurrencyUsd
} from "@mdi/js";
export default {
  props: {
    initialData: {
      type: Object,
      default: () => {},
    },
  },
  data: () => {
    return {
      imageSrc: "../../assets/logos/Recurso 5.png",
      profilesList: [],
      panel: [0],
      protocol_number: null,
      menu1: false,
      menu2: false,
      menu3: false,
      // sample_receipt_date: '',
      sampling_date: null,
      estimated_reporting_date: null,
      sampling_mode: null,
      sm_price: 0,
      presumptive_diagnosis: '',
      other_determinations: '',
    }
  },
  created() {
    this.initialize()
    this.setEditInfo()
  },
  methods: {
    disablePastDays(val)  {
       return val >= new Date().toISOString().substr(0, 10)
    },
    initialize() {
      this.profilesList = JSON.parse(JSON.stringify(profiles))
      this.panel = [0]
    },
    formatShowingDate(dates) {
      if (!dates) return null
      const [year, month, day] = dates.split('-')

      return `${day}/${month}/${year}`
    },
    formatDateToSend(dates) {
      if (!dates) return null;
      const [day, month, year] = dates.split('/');
      
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    },
    selectProfile(profile){
      const determinations = profile.determinations.map(el => el.name);
      const profileStatus = profile.checked;
      const profileName = profile.title;
      if(profile.checked) {
        this.$emit('set-profiles-det', {determinations:determinations ,profileStatus, profileName})
      } else {
        this.$emit('set-profiles-det', {determinations, profileStatus, profileName})
      }
    },
    setEditInfo(){ 
      if(this.initialData && Object.keys(this.initialData).length > 0){
        this.protocol_number = this.initialData.protocol_number
        this.sampling_date = this.initialData.sampling_date ?? null
        // this.sample_receipt_date = this.initialData.sample_receipt_date
        this.estimated_reporting_date = this.initialData.estimated_reporting_date ?? null
        this.sampling_mode = this.initialData.sampling_mode ? this.initialData.sampling_mode.id : -1
        this.presumptive_diagnosis = this.initialData.presumptive_diagnosis
        this.other_determinations = this.initialData.other_determinations
      }
    },
    updateExtraData() {
      let extraData = {
        protocol_number: this.protocol_number ?? null,
        sampling_date: this.sampling_date !== '' ? this.sampling_date : null,
        // sample_receipt_date:this.sample_receipt_date ?? null,
        estimated_reporting_date: this.estimated_reporting_date !== '' ? this.estimated_reporting_date : null,
        sampling_mode: this.sampling_mode ?? null, 
        presumptive_diagnosis: this.presumptive_diagnosis ?? null,
        other_determinations: this.other_determinations ?? null,
      } 

      if (Object.keys(this.initialData).length === 0) {
        extraData.sm_price = Number(this.sm_price) || 0;
      }

      this.$emit('update-request-data', extraData)
    },
    reset() {
      this.$refs.extraDataForm.reset()
    },
  },
  computed: {
    computedDateFormatted: {
      get() { return this.formatShowingDate(this.sample_receipt_date);},
      set(value) { this.sample_receipt_date = this.formatDateToSend(value);}
    },
    computedDateFormatted2: {
      get() { return this.formatShowingDate(this.estimated_reporting_date);},
      set(value) { this.estimated_reporting_date = this.formatDateToSend(value);}
    },
    computedDateFormatted3: {
      get() { return this.formatShowingDate(this.sampling_date);},
      set(value) { this.sampling_date = this.formatDateToSend(value);}
    },
    isValidToSend(){
      return Boolean(!!this.protocol_number && (this.sampling_mode >=0 && this.sampling_mode != null) )
    },
  },
  watch: {
    initialData: {
      handler(newVal) {
        if (Object.keys(newVal).length > 0) {
          this.setEditInfo()
          this.updateExtraData()
        }
      }, 
    },
    isValidToSend(newValue) { 
      this.$emit("is-valid-to-send", newValue , 'extraData')
    }
  },
  setup() {
    const samplingMethods = [
      {id: 0, name:"Toma de muestra en laboratorio"},
      {id:1, name: "Toma de muestra en domicilio"},
      {id:2, name:"Retiro de muestra por veterinaria"},
      {id:3, name:"Otro"}
    ]
    return {
      samplingMethods,
      icons: { 
        mdiCalendar,
        mdiFlask,
        mdiPencilPlusOutline,
        mdiPoundBoxOutline,
        mdiCurrencyUsd
        },
    };
  },
  components: {
    AppCardActions,
  },
};
</script>

<style lang="scss" scoped>
.v-list-item {
  height: 32px !important;
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}
</style>
