<template>
  <div>
    <v-dialog
        v-model="showDialog"
        max-width="800"
      >
        <v-card :loading="loading">
          <v-card-title class="text-h5 grey lighten-2">
            <v-icon class="me-2"> {{ icons.mdiSendOutline }}</v-icon>
            ¿Enviar informe? 
          </v-card-title>
          <v-card-subtitle class="pt-3"> Tilde los email que recibiran una copia del informe:</v-card-subtitle>

          <v-data-table
            :headers="headers"
            :items="contactList"
            item-key="label"
            hide-default-footer
          > 
            <template v-slot:item.name="{ item }">
              <span class="d-block font-weight-semibold text--primary text-truncate">{{
                item.name
              }}</span>
              <small>{{ item.label }}</small>
            </template>

            <!-- EMAIL -->
            <template v-slot:header.email="{ header }">
              <v-icon class="me-2">{{ icons.mdiEmailOutline }}</v-icon>
              {{ header.text.toUpperCase() }}
            </template>
            <template v-slot:item.email="{ item }">
              <div class="d-flex my-auto">
                <v-checkbox
                  class=""
                  dense
                  :disabled="!item.email"
                  v-model="item.selectedEmail"
                >
                </v-checkbox>
                <span class="my-2">
                  {{ item.email || 'sin registro'}}
                </span>
              </div>
            </template>

            <!-- PHONE -->
            <!-- <template v-slot:header.phone="{ header }">
              <v-icon class="me-2">{{ icons.mdiWhatsapp }}</v-icon>
              {{ header.text.toUpperCase() }}
            </template>
            <template v-slot:item.phone="{ item }">
              <div class="d-flex my-auto">
                <v-checkbox
                  class=""
                  dense
                  :disabled="!item.phone"
                  v-model="item.selectedPhone"
                >
                </v-checkbox>
                <span class="my-2">
                  {{ item.phone || 'sin registro'}}
                </span>
              </div>
            </template> -->
          </v-data-table>
          <v-divider></v-divider>

          <v-expansion-panels v-if="notifications.length" elevation="0" flat v-model="panel" >
            <v-expansion-panel class="elevation-0">
              <v-expansion-panel-header class="ps-0">
              <v-card-title>
                Registro de envíos
              </v-card-title>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div v-if="notifications.length">
                  <v-card-text v-for=" item in notifications"> {{ `- Informe envíado el: ${item.datetime} hs al email ${item.receiver.email}` }}</v-card-text>
                </div> 
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-divider></v-divider>
           
          <v-card-actions>
            <v-spacer></v-spacer>
            
            <v-btn
            color="error"
            outlined
            :disabled="loading"
            @click="closeDialog"
            >
              CANCELAR
            </v-btn>         
            <v-btn
            color="success" 
            :disabled="loading"
            @click="checkPayment"
            >
              ENVIAR
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
      v-model="paymentFaultModal"
      width="auto"
      >
        <v-card>
          <v-card-title>
          {{ paymentFaultMsg }}
          </v-card-title>
          <v-divider></v-divider>
          <v-card-actions>
          <v-spacer></v-spacer>
            <v-btn
            color="error"
            outlined
            @click="paymentFaultModal = !paymentFaultModal"
            >
              CANCELAR
            </v-btn>
            <v-btn
            color="primary" 
            :disabled="loading"
            @click="continueSendReport"
            >
              CONTINUAR
            </v-btn>
          <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <alert-pop-up ref="alert_pop_up"/>
  </div>
</template>

<script>
import AlertPopUp from '@/components/AlertPopUp.vue';
import {
  mdiClose,
  mdiSendOutline,
  mdiEmailOutline,
  mdiWhatsapp
} from '@mdi/js'

export default {
  data: () => {
    return {
      showDialog: false,
      contactList: [],
      notifications: [],
      panel: null,
      selectedContacts: [],
      reportId: null,
      loading: false,
      paymentFaultModal: false,
      paymentFaultMsg: '',
      headers: [
        { text: '', align: 'left', sortable: false, value: 'name'},
        { text: 'MAIL', align: 'left',sortable: false, value: 'email'},
        // { text: 'WHATSAPP', align: 'left', sortable: false, value: 'phone'},
      ]
    };
  },
  methods: {
    openSendModal(contacts, report_id, notifications) {
      this.showDialog = true
      this.contactList = contacts
      this.notifications = notifications
      this.reportId = report_id  
    },
    async checkPayment() {
      try {
        //1) primero corrobora si se cumplen las condiciones de pago.
        const response = await this.$api.checkReportPayment(this.reportId)
        //2) se cumplen, entonces enviar
        await this.sendReport()
        
      } catch (error) { 
        //3) no se cumplen, consultar si se desea enviar o no
        this.paymentFaultMsg = 'El usuario no cumple las condiciones de pago. ¿Enviar de todas formas?'
        this.paymentFaultModal = true
        return
      }
    },
    async continueSendReport(){
      await this.sendReport()
      this.paymentFaultModal = false
    },
    async sendReport(){
      this.loading = true
      const form = {
        veterinaries: [],
        tutors: [],
        veterinary_clinics: []
      };
      this.contactList.forEach(contact => {
        if (contact.selectedEmail || contact.selectedPhone) {
          const element = {
            id: contact.id,
            name: contact.name,
            email: contact.selectedEmail ? contact.email : '',
            // phone: contact.selectedPhone ? contact.phone : '',
          };
          switch (contact.label) {
            case 'Veterinario':
              form.veterinaries.push(element);
              break;
            case 'Tutor':
              form.tutors.push(element);
              break;
            case 'Clínica':
              form.veterinary_clinics.push(element);
              break;
            default:
              break;
          }
        }
      })
      
      try {
          await this.$api.sendReport(this.reportId, form)
          this.$refs.alert_pop_up.displayAlert({ message: 'Reporte enviado correctamente', type: 'info' })
          
        } catch (error) {
          this.$refs.alert_pop_up.displayAlert({ message: 'Ha ocurrido un error', type: 'error' })
        } finally { this.loading = false}

    },
    closeDialog(){
      this.showDialog = false
      this.panel = null
    },
  },
  setup() {
    return {
      icons: {
        mdiClose,
        mdiSendOutline,
        mdiEmailOutline,
        mdiWhatsapp
      }
    };
  },
  components: {
    AlertPopUp
  }
};
</script>

<style lang="scss" scoped>
.v-expansion-panel::before {
   box-shadow: none !important;
}

.v-expansion-panel--active {
   box-shadow: none !important;
}
.v-expansion-panel::after {
   box-shadow: none !important;
}

</style>
