<template>
  <div>
    <AppCardActions style="padding-top: 70px" action-collapse ref="collapseCard">
      <template slot="title"> Datos de la solicitud </template>
      <v-card-text>
        <v-form ref="requestDataForm">
          <v-row>
            <v-col cols="4" lg="4" sm="5" class="px-0">
              <v-card flat>
                <v-col >
                  <v-autocomplete
                    v-model="selectedVeterinaryUser"
                    append-icon=""
                    :prepend-inner-icon="icons.mdiMagnify"
                    :items="[selectedVeterinaryUser,...veterinaryUsersList]"
                    :search-input.sync="searchVetQuery"
                    item-text="name" 
                    :item-value="item=>item"
                    chips
                    deletable-chips
                    outlined
                    dense
                    hide-details
                    :readonly="initialData.viewMode"
                    label="Médico Veterinario"
                    @input="updateFormData"
                  >
                    <!-- cache-items -->
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-title>
                          No se encontraron
                          <strong>Veterinarios</strong>
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                    <v-list-item
                      v-show="vetCount > veterinaryUsersList.length"
                      slot="append-item"
                      class="custom-item"
                      background-color="red"
                      @click="loadMoreVets($event)"
                    >
                      Cargar mas elementos...
                    </v-list-item>
                  </v-autocomplete>
                </v-col>
                
                <v-expand-transition>
                  
                    <v-col v-if="selectedVeterinaryUser">
                      <v-select
                      v-model="selectedVeterinaryClinic"
                      :items="[initialData ? selectedVeterinaryClinic : selectedVeterinaryClinic,...veterinaryClinicList]"
                      item-text="name"
                      :item-value="item => item"
                      chips
                      deletable-chips
                      outlined 
                      dense 
                      hide-details
                      :readonly="initialData.viewMode"
                      label="Veterinaria Asociada"
                      @input="updateFormData"
                      ></v-select>
                    </v-col>
              
                </v-expand-transition>
              
                <v-col class="d-flex" cols="" v-if="selectedVeterinaryUser">
                  <v-autocomplete 
                    v-model="selectedPatient"
                    append-icon=""
                    :prepend-inner-icon="icons.mdiMagnify"
                    :items="[selectedPatient,...patientList]"
                    :search-input.sync="searchPatientQuery"
                    item-text="name"
                    :item-value="item => item"
                    chips
                    deletable-chips
                    outlined
                    dense
                    hide-details
                    :readonly="initialData.viewMode"
                    label="Paciente"
                    @input="updateFormData"
                  >
                    <!-- cache-items -->
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-title>
                          No se encontraron
                          <strong>Pacientes</strong>
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                    <template v-slot:item="{ props, item }">
                      <span>{{ `${item.name} - ${item.specie.label} ${item.race} (${item.tutor.map(el => el.name).join(', ')})` }}</span>
                    </template>
                    <v-list-item
                      v-show="patientsCount > patientList.length"
                      slot="append-item"
                      class="custom-item"
                      background-color="red"
                      @click="loadMorePatients($event)"
                    >
                      Cargar mas elementos...
                    </v-list-item>
                  </v-autocomplete>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                      v-bind="attrs"
                      v-on="on" 
                      icon 
                      color="primary"
                      :disabled="initialData.viewMode"
                      @click="$refs.patient_form_modal.openModal()"
                      > 
                        <v-icon color="primary" v-text="icons.mdiPlusCircleOutline"></v-icon>
                      </v-btn>
                    </template>
                    <span>Crear nuevo</span>
                  </v-tooltip>
                  
                </v-col>

                <v-col v-if="selectedPatient && selectedVeterinaryUser" cols="" class="d-flex">
                  <v-select
                    v-model="selectedTutor"
                    :items="[selectedTutor,...tutorList]"
                    outlined
                    dense
                    chips
                    deletable-chips
                    label="Tutor"
                    item-text="name"
                    :item-value="item => item"
                    hide-details
                    :readonly="initialData.viewMode"
                    @input="updateFormData"
                  >
                  </v-select>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                      v-bind="attrs"
                      v-on="on" 
                      icon 
                      color="primary"
                      @click="$refs.tutors_form_modal.openModal()"
                      :disabled="initialData.viewMode"
                      > 
                        <v-icon color="primary" v-text="icons.mdiPlusCircleOutline"></v-icon>
                      </v-btn>
                    </template>
                    <span>Crear nuevo</span>
                  </v-tooltip>
                </v-col>
              </v-card>
            </v-col>
            
            <v-col cols="8" lg="8" sm="7">
              <v-card flat outlined class="ms-2 mt-3">
                <v-col>
                  <v-row class="pb-3 pt-2" >
                    <v-col cols="2" lg="1">
                      <span class="me-5"> <v-icon color="primary">{{ icons.mdiHospitalBoxOutline }}</v-icon>  </span>
                    </v-col>
                    <v-col cols="10" lg="11">
                      <div class="custom-rows">
                        <span class=""> <b>Med. Vet.:</b>{{ selectedVeterinaryUser ? selectedVeterinaryUser.name : ' -' }} </span>
                        <span class="ml-3"> <b>Clinica:</b>{{ selectedVeterinaryClinic ? selectedVeterinaryClinic.name : ' -' }}</span>
                      </div>
                    </v-col>
                  </v-row>
                  <v-divider></v-divider>
                </v-col>
                
                <v-col>
                  <v-row class="pb-3">
                    <v-col cols="2" lg="1">
                      <span class="me-5"> <v-icon color="primary">{{ icons.mdiPaw }}</v-icon>  </span>
                    </v-col>
                    <v-col cols="10" lg="11">
                      <div class="custom-rows">
                        <span class=""> <b>Paciente: </b>{{ selectedPatient ? selectedPatient.name : ' -' }} </span>
                        <span class="ml-3"> <b>Sexo: </b>{{ selectedPatient ? selectedPatient.sex.label : ' -' }}</span>
                      </div>
                      <div class="custom-rows">
                        <span class=""> <b>Raza: </b> {{ selectedPatient ? selectedPatient.race : ' -' }}</span>
                        <span class="ml-3"> <b>Edad: </b>{{selectedPatient ? selectedPatient.age : ' -' }}</span>
                      </div>
                      <div class="custom-rows">
                        <span class=""> <b>Especie: </b>{{ selectedPatient ? selectedPatient.specie.label : ' -' }}</span>
                      </div>
                    </v-col>
                  </v-row>
                  <v-divider></v-divider>
                </v-col>

                <v-col>
                  <v-row class="pb-3">
                    <v-col cols="2" lg="1">
                      <span class="me-5"> <v-icon color="primary">{{ icons.mdiAccountHeartOutline }}</v-icon>  </span>
                    </v-col>
                    <v-col cols="10" lg="11">
                      <div class="custom-rows">
                        <span class=""> <b>Tutor: </b> {{ selectedTutor ? selectedTutor.name : ' -' }}</span>
                        <span class="ml-3"> <b>Tel/cel: </b> {{ selectedTutor ? selectedTutor.phone : ' -'}}</span>
                      </div>
                      <div class="custom-rows">
                        <span class=""> <b>Mail: </b> {{ selectedTutor ? selectedTutor.email : ' -' }}</span>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
                
              </v-card>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </AppCardActions>
    
    <PatientFormModal
    ref="patient_form_modal"
    :requestUserData="selectedVeterinaryUser"
    @reload-patients-list="getPatientsList"
    />

    <TutorsFormModal 
    ref="tutors_form_modal"
    :requestPatient="selectedPatient"
    @reload-tutors-list="getTutorsList"
    />

  </div>
</template>

<script>
import debounce from "../../plugins/debounce.js";
import AppCardActions from "@core/components/app-card-actions/AppCardActions.vue";
import PatientFormModal from "../Patients/PatientFormModal.vue";
import TutorsFormModal from "../Tutors/TutorsFormModal.vue";
import { 
  mdiMagnify,
  mdiHospitalBoxOutline,
  mdiPaw,
  mdiAccountHeartOutline,
  mdiPlusCircleOutline
} from "@mdi/js";

export default {
  props:{
    initialData: {
      type: Object,
      default: () => {},
    },
  },
  data: () => {
    return {
      page_size: 10,
      vetsPage: 1,
      vetCount: 0,
      searchVetQuery: "",
      veterinaryUsersList:  [],
      selectedVeterinaryUser: null,
      veterinaryClinicList: [],
      selectedVeterinaryClinic: null,
      searchPatientQuery: "",
      patientList: [],
      patientsPage: 1,
      patientsCount: 0,
      selectedPatient: null,
      tutorList: [],
      selectedTutor: null,
    };
  },
  mounted() {
    this.getVeterinaryUsersList();
    if(this.initialData && Object.keys(this.initialData).length > 0){
      this.setEditInfo()
      this.updateFormData()
    }
  },
  methods: {
    handleVetSearch: debounce(function () {
      this.vetsPage = 1;
      this.getVeterinaryUsersList();
    }, 400),
    async getVeterinaryUsersList() {
      this.loading = true;
      try {
        const data = {
          page_size: this.page_size,
          page: this.vetsPage,
          search: this.searchVetQuery,
          summary: true,
          role: 0, // Rol Veterinario = 0
        };
        const response = await this.$api.getUsersList(data);
        this.veterinaryUsersList = response.results;
        // console.log('LIST', this.veterinaryUsersList);
        this.vetCount = response.count;
      } catch (error) {
        // console.log(error);
      }
    },
    async loadMoreVets() {
      try {
        const data = {
          page: this.vetsPage + 1,
          page_size: this.page_size,
          summary: true,
          role: 0, // Rol Veterinario = 0
        };

        const response = await this.$api.getUsersList(data);
        this.veterinaryUsersList = this.veterinaryUsersList.concat(response.results); // Concatena los resultados de la nueva página con los resultados anteriores
        this.vetsPage++; // Aumenta el número de página actual
        this.vetCount = response.count; // Actualiza el número total de elementos
      } catch (error) {
        console.error(error);
      }
    },

    handlePatientSearch: debounce(function () {
      this.patientsPage = 1;
      this.getPatientsList();
    }, 400),
    async getPatientsList() {
      try {
        const data = {
          page_size: this.page_size,
          page: this.patientsPage,
          search: this.searchPatientQuery,
          summary: false,
          // userdata: this.selectedVeterinaryUser.id, //Filtering by patients from selected veterinary User (deprecated)
        };
        const response = await this.$api.getPatientsList(data);
        this.patientList = response.results;
        this.patientsCount = response.count;
      } catch (error) {
        console.log(error);
      }
    },
    async loadMorePatients() {
      try {
        const data = {
          page: this.patientsPage + 1,
          page_size: this.page_size,
          summary: false,
          // userdata: this.selectedVeterinaryUser.id, // Rol Veterinario = 0
        };

        const response = await this.$api.getPatientsList(data);
        this.patientList = this.patientList.concat(response.results); // Concatena los resultados de la nueva página con los resultados anteriores
        this.patientsPage++; // Aumenta el número de página actual
        this.patientsCount = response.count; // Actualiza el número total de elementos
      } catch (error) {
        console.error(error);
      }
    },
    async getVeterinaryClinicList() {
      try {

        const response = await this.$api.getUser(this.selectedVeterinaryUser.id);
        this.veterinaryClinicList = response.data.veterinary_clinics
        
      } catch (error) {
        console.log(error);
      }
    },
    async getTutorsList() {
      try {
        const data = {
          page_size: this.page_size,
          page: 1,
          summary: false,
          patient: this.selectedPatient.id
        };
        const response = await this.$api.getTutorsList(data);
        this.tutorList = response.results;
        
      } catch (error) {
        console.log(error);
      }
    },
    setEditInfo(){ 
      if(this.initialData && Object.keys(this.initialData).length > 0){
        this.selectedVeterinaryUser = JSON.parse(JSON.stringify(this.initialData.userdata))
        this.selectedVeterinaryUser.veterinary_clinics = this.initialData.veterinary_clinic
        this.selectedVeterinaryClinic = JSON.parse(JSON.stringify(this.initialData.veterinary_clinic))
        this.selectedPatient = JSON.parse(JSON.stringify(this.initialData.patient))
        this.selectedTutor = JSON.parse(JSON.stringify(this.initialData.tutor))}
    },
    updateFormData() {
      // console.log(this.isValidToSend);
      this.$emit('update-request-data', {
        userdata: this.selectedVeterinaryUser ? this.selectedVeterinaryUser.id : null ,
        veterinary_clinic: this.selectedVeterinaryClinic ? this.selectedVeterinaryClinic.id : null,
        patient: this.selectedPatient ? this.selectedPatient.id : null,
        tutor: this.selectedTutor ? this.selectedTutor.id : null,
      })

    },
    reset() {
      this.$refs.requestDataForm.reset()
      this.updateFormData()
      this.selectedVeterinaryUser = null
      this.selectedPatient = null
      this.selectedVeterinaryClinic = null
      this.selectedTutor = null
    },
  },
  computed: {
    isValidToSend(){
      return Boolean(!!this.selectedVeterinaryUser && !!this.selectedPatient)
    },
  },
  watch: {
    initialData(newVal) {
        if (Object.keys(newVal).length > 0) {
          this.setEditInfo()
          this.updateFormData()
        }
    },
    searchVetQuery(val) {
        this.handleVetSearch(val);
    },
    searchPatientQuery(val) {
        this.handlePatientSearch(val);
    },
    selectedVeterinaryUser(val) {
      // console.log(val);
      if (val && val.id) {
        this.getPatientsList();
        this.getVeterinaryClinicList()
        // console.warn(JSON.parse(JSON.stringify(this.selectedVeterinaryUser)))
      } else {
        this.patientList = [];
        this.selectedPatient = null 
        this.selectedVeterinaryClinic = null
        this.tutorList = [];
        this.selectedTutor = null;
      }
    },
    selectedPatient(val) {
      if (val) {
        // verifica si selectedPatient es distinto de null y tiene una propiedad id
        this.getTutorsList();
      } else {
        this.tutorList = [];
        this.selectedTutor =  null;
      }
    },
    isValidToSend(newValue) { 
      this.$emit("is-valid-to-send", newValue, 'formdata')
    }
  },
  setup() {
    return {
      icons: { 
        mdiMagnify,
        mdiHospitalBoxOutline,
        mdiPaw,
        mdiAccountHeartOutline,
        mdiPlusCircleOutline
      },
    };
  },
  components: { AppCardActions, PatientFormModal, TutorsFormModal },
};
</script>

<style lang="scss" scoped>
.custom-item {
  background-color: rgba(151, 76, 232, 0.1);
  cursor: copy;
}

@media (max-width: 1260px) {
  .custom-rows > span {
  display:block;
  margin-left: 0 !important;
}
}

</style>
