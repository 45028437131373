<template>
  <v-dialog fullscreen hide-overlay class="hide-scroll" v-model="showingReportModal">

      <v-card class="modal-background hide-scroll" >

        <v-app-bar elevation="3" class="tool-bar" >
          <v-toolbar-title class="text-h5 grey lighten-2" >{{ report && report.sku ? report.sku : '-' }}
          </v-toolbar-title>
            <v-chip
            :color="status === 0 ? 'primary' : (status && status === 1) ? 'accent' : 'success'"
            :class="status === 0 ? 'primary--text' : (status && status === 1)  ? 'accent--text' : 'success--text'"
            class="v-chip-light-bg ml-6"
            >{{ status | showingStatus }}
            </v-chip>

            <v-chip v-if="report.pdf"
            color="accent"
            class="accent--text v-chip-light-bg ml-6"
            @click="openPDF(report.pdf)"
            >{{ 'PDF' }}
            </v-chip>
            
            <v-chip 
            color="primary"
            @click="createReportPreview(report.id)"
            outlined
            class="primary--text v-chip-light-bg ml-6"
            >Generar vista previa de informe
            </v-chip>

            <v-switch
            v-if="status !== 0"
            color="success"
            class="ml-6 py-auto"
            hide-details
            v-model="filesVisibility"
            :label="filesVisibility ? 'Habilitado' : 'Deshabilitado'"
            @click="toggleFilesVisibility(report.id)"
            ></v-switch>

          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-btn icon color="primary" @click="close()">
          <v-icon>{{icons.mdiClose}}</v-icon>
          </v-btn>
        </v-app-bar>

        <v-progress-linear v-if="loading"
          indeterminate
          color="primary"
        ></v-progress-linear>

        <!-- <v-divider class="mt-n2"></v-divider> -->
        <v-row >

          <v-col class="mt-3" cols="4" sm="5" md="4" lg="3">
          <v-card class="my-card">
            <v-card-actions class="pa-3" >
              <!-- <v-spacer v-if="status.id !== 2" class="spacer"></v-spacer> -->

                <v-col v-if="status !== 2">
                  <v-btn
                  v-if="status === 0"
                  color="primary"
                  class=""
                  @click="processReport"
                  small
                  >
                    <v-icon class="mr-2">{{ icons.mdiCogOutline }}</v-icon>
                    Procesar
                  </v-btn>

                  <v-tooltip v-if="status === 1" top>
                    <template #activator="{ on, attrs }">
                      <v-btn
                      class="me-n7"
                        color="success "
                        v-bind="attrs"
                        v-on="on"
                        small
                        @click="() => showingFinishReportModal = !showingFinishReportModal"
                      >
                      <v-icon class="mr-2">{{ icons.mdiCheckCircleOutline }}</v-icon>
                      Finalizar
                      </v-btn>
                    </template>
                    <span>Finalizar el informe y generar PDF</span>
                  </v-tooltip>
                </v-col >

                <v-col v-if="status === 2">
                  <v-btn
                  class=""
                  color="warning"
                  small
                  @click="() => showEditFinished = !showEditFinished"
                  >
                    <v-icon class="mr-2">{{ icons.mdiCogOutline }}</v-icon>
                    Editar
                  </v-btn>

                  <v-btn
                  class="ms-3"
                  color="accent"
                  small
                  @click="sendReportExport"
                  >
                    <v-icon class="mr-2">{{ icons.mdiSendOutline }}</v-icon>
                    Enviar PDF
                  </v-btn>

                </v-col>

            </v-card-actions>

            <v-divider class="mx-2"></v-divider>

            <v-card-title class="pt-13">
              <span class="me-3">Datos de la solicitud </span>
              <v-icon>{{ icons.mdiPoundBoxOutline }}</v-icon> {{ report.request ? report.request.protocol_number : '-' }}
            </v-card-title>
              <v-divider class="mx-2"></v-divider>

              <v-card-text> <b>creada:</b> {{request ? request.created_at : '-' | formatDateTime }}</v-card-text>
              <v-card-text> <b>Toma de muestra:</b> {{request ? request.sampling_date : '-' | formatShowingDate}}</v-card-text>
              <v-card-text> <b>Recepción de muestra:</b> {{ report.request ? report.request.sample_receipt_date : '-' | formatShowingDate}}</v-card-text>
                <v-divider class="mx-4"></v-divider>
              <div v-if="request && Object.keys(request.userdata).length">
                <v-card-text> <b>Med. Vet. Remitente:</b> {{  request ? request.userdata.name : '-' }}</v-card-text>
                <v-card-text>  <b>Tel:</b> {{ request && request.userdata.phone ? request.userdata.phone : '-'}}</v-card-text>
                <v-card-text>  <b>E-mail:</b> {{request && request.userdata.email ? request.userdata.email : ''}}</v-card-text>
              </div>
              <div v-else>
                <v-card-text class="font-italic error--text"> el usuario ha sido eliminado de registros</v-card-text>
              </div>
                <v-divider class="mx-4"></v-divider>
              <div v-if="request && Object.keys(request.patient).length">
                <v-card-text>  <b>Paciente:</b> {{ request ? request.patient.name : '-'}}</v-card-text>
                <v-card-text>  <b>Especie:</b> {{ request ? request.patient.specie.label : '-' }}</v-card-text>
                <v-card-text>  <b>Raza:</b> {{ request ? request.patient.race : '-' }}</v-card-text>
                <v-card-text>  <b>Sexo:</b> {{ request ? request.patient.sex.label : '-' }}</v-card-text>
                <v-card-text> <b>Edad:</b> {{ request ? request.patient.age : '-' }} Año/s</v-card-text>
              </div>
              <div v-else>
                <v-card-text class="font-italic error--text"> el paciente ha sido eliminado de registros</v-card-text>
              </div>
                <v-divider class="mx-4"></v-divider>
                <v-card-text>  <b>Tutor/a:</b> {{ request && request.tutor.name ? request.tutor.name : '-' }}</v-card-text>
                <v-card-text> <b>Tel:</b> {{ request && request.tutor.phone ? request.tutor.phone : '-' }}</v-card-text>
                <v-card-text> <b>E-mail:</b> {{ request && request.tutor.email ? request.tutor.email : '-' }}</v-card-text>
                <v-divider class="mx-4"></v-divider>
              <v-card-text> <b>Diagnóstico presuntivo:</b> {{ request ? request.presumptive_diagnosis : '-'}}</v-card-text>
              <v-card-text> <b>Otras determinaciones:</b> {{request ? request.other_determinations : '-' }}</v-card-text>

              <v-divider class=" mx-2 mt-3"></v-divider>

              <v-btn @click="() => showFilesModal = !showFilesModal"
              >Anexos generales
              </v-btn>

          </v-card>
        </v-col>

        <v-col cols="8" sm="7" md="8" lg="9" class="ps-0" v-if="report.special_categories && report.special_categories">

          <div class="mt-3 my-list">
            <v-row class="justify-center me-3">
              <v-app-bar dense class="fixed-bar"
              elevation="1"
              >
                <v-tabs
                  v-model="tab"
                  show-arrows

                >
                  <v-tab active-class="active-tab"
                    v-for="category in report.special_categories"
                    :key="category.name"
                  >
                    {{ category.label }}
                  </v-tab>
                </v-tabs>
              </v-app-bar>
              <v-tabs-items v-model="tab" style="width: 100%;">
                <v-tab-item class="tabs-item"
                  v-for="category in report.special_categories"
                  :key="category.name"
                >
                  <v-expansion-panels multiple :value="panel" class="me-6">
                    <template v-for="(determination, index) in category.determinations">

                      <v-col cols="12" lg="6" md="6" sm="12" class="pe-1">
                        <v-form ref="reports_refs" autocomplete="off" @submit.prevent="sendReport(index, determination, category.id, reportId)">
                          <v-card class="">
                              <v-expansion-panel>
                                <v-expansion-panel-header>
                                  <v-card-title class="justify-center">
                                    {{ determination.structure.lectura }}
                                  </v-card-title>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>

                                  <v-card-text>
                                    <v-checkbox
                                    v-model="determination.anexos"
                                    >
                                      <template v-slot:label>
                                        <v-tooltip top>
                                          <template v-slot:activator="{ on, attrs }">
                                            <span
                                            v-bind="attrs"
                                            v-on="on"
                                            > Determinaciones anexas</span>
                                          </template>
                                          <span v-if="determination.anexos"> los datos se cargaran en un informe aparte</span>
                                          <span v-else>cargando datos manualmente</span>
                                        </v-tooltip>
                                      </template>
                                    </v-checkbox>

                                    <v-card-text v-if="determination.anexos" class="mt-6">
                                        <div v-if="determination.file && determination.file.url">
                                          <span> - Un archivo cargado (Ver PDF) </span>
                                          <v-btn icon @click="openPDF(determination.file.url)">
                                            <v-icon color="primary">{{ icons.mdiFilePdfBox }}</v-icon>
                                          </v-btn>
                                          <span> {{ determination.file.name}}</span>
                                        </div>
                                        <span v-else> - No se encontraron archivos</span>
                                    </v-card-text>

                                    <v-row v-show="determination.anexos">
                                      <v-col>
                                        <v-file-input class="mt-6"
                                        accept=".pdf"
                                        ref="file"
                                        v-model="determinationFiles['anexo_' + determination.id + '_send']"
                                        show-size
                                        label="Informe a anexar"
                                        hide-details
                                        outlined
                                        ></v-file-input>
                                      </v-col>
                                    </v-row>
                                    <div v-show="!determination.anexos">
                                    <v-row>
                                      <v-col cols="12" v-for="input in determination.structure.parametros" style="display: flex">
                                        <v-text-field
                                          ref="str_ref"
                                          v-if="input.tipo === 'str'"
                                          v-model="input.valor"
                                          :label="input.lectura ? input.lectura : determination.structure.lectura"
                                          :rules="[
                                            v => (!v || v.length <= input.limite) || 'Demasiados carácteres'
                                          ]"

                                          style="width: 45%"
                                          :readonly="(status && status === 2) || viewMode"
                                          :class="(status && status === 2) || viewMode ? 'readonly-input' : ''"
                                        >
                                        <template v-slot:append >
                                          <div class="mt-1">{{ input.unidad }}</div>
                                        </template>
                                        </v-text-field>

                                        <v-textarea
                                          v-if="input.tipo === 'text' || input.tipo === 'texto'"
                                          v-model="input.valor"
                                          auto-grow
                                          :label="input.lectura ? input.lectura : determination.structure.lectura"
                                          rows="1"
                                          row-height="0"

                                          :counter="input.limite"
                                          :rules="[
                                            v => (!v || v.length <= input.limite) || 'Demasiados carácteres'
                                          ]"
                                          :readonly="(status && status === 2) || viewMode"
                                          :class="(status && status === 2) || viewMode ? 'readonly-input' : ''"
                                        ></v-textarea>

                                        <v-select
                                          v-if="input.tipo === 'dropdown'"
                                          v-model="input.valor"
                                          :label="input.lectura ? input.lectura : determination.structure.lectura"
                                          :items="input.options.split(',')"
                                          :rules="[
                                            (v) => !!v || 'Campo requerido',
                                          ]"
                                          :readonly="(status && status === 2) || viewMode"
                                          :class="(status && status === 2) || viewMode ? 'readonly-input' : ''"
                                          @change="hemopatogenos(input, determination.structure.parametros, $event)"
                                        ></v-select>

                                        <v-select
                                          v-if="input.tipo === 'checkbox'  && input.nombre !== 'resultado_esp_hemopatogenos'"
                                          multiple
                                          v-model="input.valor"
                                          :label="input.lectura ? input.lectura : determination.structure.lectura"
                                          :items="input.options.split(',')"
                                          :rules="[
                                            (v) => !!v || 'Campo requerido',
                                          ]"
                                          :readonly="(status && status === 2) || viewMode"
                                          :class="(status && status === 2) || viewMode ? 'readonly-input' : ''"
                                        ></v-select>

                                        <v-select
                                          v-if="input.tipo === 'checkbox' && input.nombre === 'resultado_esp_hemopatogenos' && mostrarHemopatogenos(determination.structure.parametros)"
                                          multiple
                                          v-model="input.valor"
                                          :label="input.lectura ? input.lectura : determination.structure.lectura"
                                          :items="input.options.split(',')"
                                          :rules="[
                                            (v) => !!v || 'Campo requerido',
                                          ]"
                                          :readonly="(status && status === 2) || viewMode"
                                          :class="(status && status === 2) || viewMode ? 'readonly-input' : ''"
                                        ></v-select>

                                        <span v-if="input.referencia && !!referencia(input)" class="ms-9" style="max-width: 45%;">
                                          <b>Val. Ref.:</b><br>
                                          {{ referencia(input) }}
                                        </span>
                                      </v-col>
                                    </v-row>
                                    <div v-if="determination.structure.categorias && determination.structure.categorias.length" class="mt-8">
                                      <v-row v-for="category in determination.structure.categorias" :key="category.nombre">
                                        <v-col cols="12" >
                                          <div class="font-weight-medium text-subtitle-1 text-center me-5">
                                            {{  category.lectura }}
                                          </div>
                                        </v-col>
                                        <v-col cols="12" v-for="input in category.parametros" style="display: flex">

                                          <v-text-field
                                            v-if="defaultStrInputs(input, category)"
                                            v-model="input.valor"
                                            :label="input.lectura ? input.lectura : determination.structure.lectura"
                                            :rules="[
                                              v => (!v || v.toString().length <= input.limite) || 'Demasiados carácteres'
                                            ]"
                                            style="width: 45%"
                                            :readonly="(status && status === 2) || viewMode"
                                            :class="(status && status === 2) || viewMode ? 'readonly-input' : ''"
                                          >
                                          <template v-slot:append >
                                            <div class="mt-1">{{ input.unidad }}</div>
                                          </template>
                                          </v-text-field>

                                          <!-- Inputs especificos de hemograma - hemograma -->
                                          <v-text-field
                                            v-if="specificBloodTestInputs(input, category)"
                                            v-model="input.valor"
                                            :label="input.lectura ? input.lectura : determination.structure.lectura"
                                            :rules="[
                                              v => (!v || v.toString().length <= input.limite) || 'Demasiados carácteres'
                                            ]"
                                            style="width: 45%"
                                            :readonly="(status && status === 2) || viewMode"
                                            :class="(status && status === 2) || viewMode ? 'readonly-input' : ''"
                                            @input="bloodTest(category, $event)"
                                          >
                                          <template v-slot:append >
                                            <div class="mt-1">{{ input.unidad }}</div>
                                          </template>
                                          </v-text-field>

                                          <!-- Inputs especificos de hemograma - leucograma -->
                                          <template v-if="input.tipo === 'str' && category.nombre === 'leucograma'">
                                            <!-- LEUCOCITOS -->
                                            <v-text-field
                                              v-if="input.unidad === '/ul' && input.nombre === 'leucocitos'"
                                              v-model="input.valor"
                                              :label="input.lectura ? input.lectura : determination.structure.lectura"
                                              :rules="[
                                                v => (!v || v.toString().length <= input.limite) || 'Demasiados carácteres'
                                              ]"
                                              style="width: 45%"
                                              :readonly="(status && status === 2) || viewMode"
                                              :class="(status && status === 2) || viewMode ? 'readonly-input' : ''"
                                              @input="leukogramPercentages(category.parametros, $event)"
                                            >
                                            <template v-slot:append >
                                              <div class="mt-1">{{ input.unidad }}</div>
                                            </template>
                                            </v-text-field>

                                            <!-- valores ingresados por el usuario '%' -->
                                            <v-text-field
                                              v-if="input.unidad === '%'"
                                              v-model="input.valor"
                                              :label="input.lectura ? input.lectura : determination.structure.lectura"
                                              :rules="[
                                                v => (!v || v.length <= input.limite) || 'Demasiados carácteres'
                                              ]"
                                              :readonly="(status && status === 2) || viewMode"
                                              :class="(status && status === 2) || viewMode ? 'readonly-input' : ''"
                                              @input="leukogramPercentages(category.parametros, $event)"
                                            >
                                            <template v-slot:append >
                                              <div class="mt-1">{{ input.unidad }}</div>
                                            </template>
                                            </v-text-field>

                                            <!-- Valores Calculados '/ul'-->
                                            <v-text-field
                                              v-if="input.unidad === '/ul' && input.nombre !== 'leucocitos'"
                                              v-model="input.valor"
                                              :label="input.lectura ? input.lectura : determination.structure.lectura"
                                              readonly
                                            >
                                            <template v-slot:append >
                                              <div class="mt-1">{{ input.unidad }}</div>
                                            </template>
                                            </v-text-field>

                                          </template>

                                          <v-textarea
                                            v-if="input.tipo === 'text' || input.tipo === 'texto'"
                                            auto-grow
                                            v-model="input.valor"
                                            :label="input.lectura ? input.lectura : determination.structure.lectura"
                                            rows="3"
                                            row-height="20"
                                            :counter="input.limite"
                                            :rules="[
                                              v => (!v || v.length <= input.limite) || 'Demasiados carácteres'
                                            ]"
                                            :readonly="(status && status === 2) || viewMode"
                                            :class="(status && status === 2) || viewMode ? 'readonly-input' : ''"
                                          ></v-textarea>

                                          <v-select
                                            v-if="input.tipo === 'dropdown'"
                                            v-model="input.valor"
                                            :label="input.lectura ? input.lectura : determination.structure.lectura"
                                            :items="input.options.split(',')"
                                            :rules="[
                                              (v) => !!v || 'Campo requerido',
                                            ]"
                                            :readonly="(status && status === 2) || viewMode"
                                            :class="(status && status === 2) || viewMode ? 'readonly-input' : ''"
                                          ></v-select>

                                          <v-select
                                            v-if="input.tipo === 'checkbox'  && input.nombre !== 'resultado_esp_hemopatogenos'"
                                            multiple
                                            v-model="input.valor"
                                            :label="input.lectura ? input.lectura : determination.structure.lectura"
                                            :items="input.options.split(',')"
                                            :rules="[
                                              (v) => !!v || 'Campo requerido',
                                            ]"
                                            :readonly="(status && status === 2) || viewMode"
                                            :class="(status && status === 2) || viewMode ? 'readonly-input' : ''"
                                          ></v-select>

                                          <span v-if="input.referencia && !!referencia(input)" class="ms-9" style="max-width: 45%;">
                                          <b>Val. Ref.:</b><br>
                                          {{ referencia(input) }}
                                        </span>

                                        </v-col>
                                      </v-row>
                                    </div>
                                  </div>
                                  </v-card-text>
                                  <v-card-actions class="pt-5">
                                    <v-spacer></v-spacer>
                                    <v-btn small type="submit" color="primary" v-if="status && status !== 1, !viewMode"
                                    :disabled="determination.anexos && !determinationFiles['anexo_' + determination.id + '_send']"
                                    >
                                      Guardar
                                    </v-btn>
                                  </v-card-actions>
                                </v-expansion-panel-content>
                              </v-expansion-panel>
                            </v-card>
                        </v-form>
                        </v-col>
                      </template>
                    </v-expansion-panels>
                </v-tab-item>
              </v-tabs-items>

            </v-row>
          </div >
        </v-col>

        </v-row>

      </v-card>

      <alert-pop-up ref="alert_pop_up"/>
      <send-report-modal ref="send_report_modal"/>

      <!-- Finish button modal -->
      <v-dialog
          v-model="showingFinishReportModal"
          width="auto"
      >
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">¿Estás seguro de finalizar el informe?</v-card-title>
          <v-card-subtitle class="pt-3">Asegurate de haber completado todos los campos</v-card-subtitle>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
            color="error"
            outlined
            @click="() => showingFinishReportModal = !showingFinishReportModal"
            >
              CANCELAR
            </v-btn>
            <v-btn
            color="success"
            :disabled="loading"
            @click="finishReport()"
            >
              Finalizar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Editing button Modal -->
      <v-dialog
          v-model="showEditFinished"
          width="auto"
      >
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            <v-icon class="me-2"> {{ icons.mdiAlertOutline }}</v-icon>
            Este informe fue Finalizado
          </v-card-title>
          <v-card-subtitle class="pt-3 ms-7"> ¿Esta seguro de volver a procesarlo?</v-card-subtitle>
          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
            color="error"
            outlined
            @click="() => showEditFinished = !showEditFinished"
            >
              CANCELAR
            </v-btn>
            <v-btn
            color="success"
            @click="() => enableEditing()"
            >
              CONFIRMAR
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- FILES Modal -->
      <v-dialog
        v-model="showFilesModal"
        width="auto"
      >
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            DETERMINACIONES ANEXAS
          </v-card-title>
          <v-card-subtitle class="pt-3"> Envie aqui los pdf anexos generales</v-card-subtitle>
            <v-list rounded>
              <v-list-item-group v-if="report.general_annexes"
                v-model="selectedFile"
                color="primary"
              >
                <v-list-item
                  v-for="(file, i) in report.general_annexes"
                  :key="i"
                >
                  <v-list-item-icon>
                    <v-icon v-text="icons.mdiFileMultipleOutline"></v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title v-text="file.name"></v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn icon @click="openPDF(file.url)">
                      <v-icon color="primary" v-text="icons.mdiEyeOutline"></v-icon>
                    </v-btn>
                  </v-list-item-action>
                  <v-list-item-action>
                    <v-btn icon @click="deleteFile(file.id)">
                      <v-icon color="error" v-text="icons.mdiDelete"></v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list-item-group>
            </v-list>
            <v-form ref="annexes_form">
              <v-card-text>
                <v-col>
                  <v-file-input class="mt-6"
                  accept=".pdf"
                  ref="file"
                  v-model="filesToSend"
                  multiple
                  show-size
                  label="Informe/s a anexar"
                  hide-details
                  outlined
                  :disabled="status === 2"
                  ></v-file-input>
                </v-col>
              </v-card-text>
            </v-form>
          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
            color="error"
            outlined
            @click="() => showFilesModal = !showFilesModal"
            >
              CANCELAR
            </v-btn>
            <v-btn
            color="success"
            @click="sendGeneralAnnexes(reportId)"
            :disabled="!filesToSend.length"
            >
              ENVIAR
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

  </v-dialog>
</template>

<script>
import AlertPopUp from '@/components/AlertPopUp.vue';
import {
  mdiDelete,
  mdiClose,
  mdiCogOutline,
  mdiSendOutline,
  mdiEyeOutline,
  mdiPencilOutline,
  mdiAlertOutline,
  mdiCheckCircleOutline,
  mdiFilePdfBox,
  mdiPoundBoxOutline
} from '@mdi/js'
import SendReportModal from './SendReportModal.vue';
import debounce from '../../plugins/debounce.js'

export default {
  data: () => {
    return {
      report: {},
      reportId: null,
      request: null,
      panel: [0],
      form: {},
      notifications: [],
      filesVisibility: false, //habilita/deshabilita visibilidad de informes/anexos para veterinarios
      filesToSend: [],
      selectedFile: 0,
      determinationFiles: {},
      showFilesModal: false,
      status: 0,
      currentPage: 0,
      loading: true,
      showingReportModal: false,
      showingFinishReportModal: false,
      showEditFinished: false,
      viewMode: null,
      tab: null,
      leukogram: {}
    }
  },
  filters: {
    formatShowingDate(value) {
      if (!value) return '-';

      const [year, month, day] = value.split('-')
      return `${day}/${month}/${year}`
    },
    formatDateTime(value){
      const date = value.split(' ')[0].split('-').reverse().join('/');
      const time = value.slice(11, 16);
      return `${date} - ${time} hs`;
    },
    showingStatus(value) {
      if(value === 0 ) return 'Pendiente'
      if(value === 1 ) return 'Procesando'
      if(value === 2 ) return 'Finalizado' //cambiar cuando este el back
    }
  },
  methods: {
    async openReportModal(data) {
      this.reportId = data.reportId
      data.request ? this.request = data.request : this.request = null
      data.viewMode ? this.viewMode = true : this.viewMode = null
      this.panel = [0]
      this.status = this.request.status.id
      this.showingReportModal = true
      this.getReport()
      if(this.status !== 0) {
        this.getFilesVisibility()
      }
    },
    async getReport() {
      this.loading = true
      try {
        const response = await this.$api.getReport(this.reportId)
        this.showReportNotification()
        this.report = JSON.parse(JSON.stringify(response))
        this.status= response.status
      } catch(e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async getFilesVisibility(){
      try {
        const response = await this.$api.getFilesVisibility(this.reportId)
        this.filesVisibility = response
      } catch (error) {
        console.log(error)
      }
    },
    async showReportNotification(){
      try {
        const response = await this.$api.showReportNotification(this.reportId)
        this.notifications = response.data.notifications

      } catch (error) {
        console.error(error)
      }
    },
    async createReportPreview(id) {
      try {
        const response = await this.$api.createReportPreview(id)
        console.log(response)
        this.getReport()
        this.$refs.alert_pop_up.displayAlert({ message: response.data, type: 'success' })
      } catch(e) {
        console.log(e)
        if(e.response.data){
          this.$refs.alert_pop_up.displayAlert({ message: e.response.data[0], type: 'error' })
        } else {
          this.$refs.alert_pop_up.displayAlert({ message: 'Ha ocurrido un errro, intente nuevamente mas tarde', type: 'error' })
        }
      }
    },
    close() {
      this.report = {}
      this.form = {}
      this.showingReportModal = false
    },
    openPDF(url){
      window.open(url, '_blank')
    },
    async toggleFilesVisibility(reportId){
      try {
        const response = await this.$api.toggleFilesVisibility(reportId)
        this.getFilesVisibility()
        console.log(response);
        this.$refs.alert_pop_up.displayAlert({ message: response.data, type: 'success' })
      } catch (error) {
        this.getFilesVisibility()
        if(error.response.data){
          this.$refs.alert_pop_up.displayAlert({ message: error.response.data[0], type: 'error' })
        } else {
          this.$refs.alert_pop_up.displayAlert({ message: 'Ha ocurrido un errro, intente nuevamente mas tarde', type: 'error' })
        }
      }
    },
    async sendGeneralAnnexes(reportId){
      let formData = new FormData();

        try {
          for (let i = 0; i < this.filesToSend.length; i++) {
            formData.append('files', this.filesToSend[i])
          }

          const response = await this.$api.sendGeneralAnnexes(reportId, formData)
          await this.processReport()
          this.getReport()
          this.$emit("refresh-list")
          this.$refs.alert_pop_up.displayAlert({ message: 'Se anexaron informes correctamente!', type: 'success' })
        } catch (error) {
          this.$refs.alert_pop_up.displayAlert({ message: error.response.data[0], type: 'error' })
        } finally {
          this.showFilesModal = false
          this.$refs.annexes_form.reset()
        }
    },
    //Delete genneral annexes files
    async deleteFile(file_id){
      try {
        const response = await this.$api.deleteAnnexesFile(this.reportId, {id: file_id})
        this.getReport()
        this.$refs.alert_pop_up.displayAlert({ message: 'Archivo eliminado correctamente!', type: 'success' })
      } catch (error) {
        console.error(error)
        this.$refs.alert_pop_up.displayAlert({ message: 'No se pudo eliminar ese archivo', type: 'error' })
      }
    },
    async sendReport(index, determination, category, reportId) {
        try {
          const form = {
            data: {
              determinations: [],
            },
          }

          if (determination.anexos){

            let formData = new FormData();

            const form2 = {
              id: determination.id,
              nombre: determination.name,
              special_category: category,
              anexos: determination.anexos,
            }

            if (this.determinationFiles['anexo_' + determination.id + '_send']){
                formData.append('files', this.determinationFiles['anexo_' + determination.id + '_send'])
            }

            for(const key in form2) {
                formData.set(key, form2[key])
            }

            const response = await this.$api.sendDeterminationPDF(reportId, formData)

            this.determinationFiles['anexo_' + determination.id + '_send'] = []

            if(response.status === 200) {
              this.$refs.alert_pop_up.displayAlert({ message: 'Anexo guardados correctamente.', type: 'success' })
            }

          } else {
            form.data.determinations.push({
              id: determination.id,
              special_category: category,
              nombre: determination.name,
              anexos: determination.anexos,
              parametros: determination.structure.parametros && determination.structure.parametros.length
              ? determination.structure.parametros.map(param => {
                  return {nombre: param.nombre, valor: param.valor ?? ''}
                })
              : [],
              categorias: determination.structure.categorias && determination.structure.categorias.length
              ? determination.structure.categorias.map(cat => {
                  return {
                    ...(cat.parametros && cat.parametros.length ? {
                      parametros: cat.parametros.map(param => {
                        return { nombre: param.nombre, valor: param.valor ?? '' };
                      }),
                      nombre: cat.nombre
                    } : {})
                  };
                }).filter(Boolean) // <--- Añade esta línea
              : []
              })


            const response = await this.$api.updateReport(reportId, form)

            if(response.status === 200) {
            this.$refs.alert_pop_up.displayAlert({ message: 'Datos guardados correctamente.', type: 'success' })
            }
          }

          this.processReport()
          this.getReport()
          this.$emit("refresh-list")
        } catch(e) {
          console.log(e)
          if(e.response.status === 403){
            this.$refs.alert_pop_up.displayAlert({ message: "No se pudo cargar el archivo. Por favor, vuelva a intentarlo", type: 'error' })
          }
        }

    },
    referencia (input) {
      const especie = this.request.patient.specie.label.toLowerCase();
      if (input.referencia && input.referencia.hasOwnProperty(especie)) {
        return input.referencia[especie];
      }
      return '';
    },
    validateAllInputs() {
      let validToSend = true
      for (const key in this.form) {
        if(this.form[key] === '') {
          validToSend = false

        }
      }
      return validToSend
    },
    async processReport() {
      if(this.status !== 1) {
        try {
          const response = await this.$api.updateReport(this.reportId, {})

          this.getReport()
          this.$emit("refresh-list")
        } catch(e) {
          console.log(e)
        }
      }
    },
    async finishReport() {
      this.loading = true;
        try {
          const response = await this.$api.finishReport(this.reportId)

          this.showingFinishReportModal = false
          this.$refs.alert_pop_up.displayAlert({ message: 'Informe Finalizado exitosamente!', type: 'success' })
          this.getReport()
          this.getFilesVisibility()
          this.$emit("refresh-list")
          this.loading = false;
        } catch(e) {
          if(e.response.data[0]){
            this.$refs.alert_pop_up.displayAlert({ message: e.response.data[0], type: 'error' })
          } else {
            this.$refs.alert_pop_up.displayAlert({ message: 'Ha ocurrido un error, intente nuevamente', type: 'error' })
          }
        }
    },
    async enableEditing() {
      try {

          const response = await this.$api.updateReport(this.reportId, {})

          this.showEditFinished = false
          this.getReport()
          this.$emit("refresh-list")
        } catch(e) {
          console.log(e)
      }
    },
    sendReportExport() {
      const contacts = [
        {id:this.request.userdata.id ,label: 'Veterinario',name: this.request.userdata.name, email: this.request.userdata.email, phone: this.request.userdata.phone},
        {id:this.request.veterinary_clinic.id ,label: 'Clínica',name: this.request.veterinary_clinic.name, email: this.request.veterinary_clinic.email, phone: this.request.veterinary_clinic.phone},
        {id:this.request.tutor.id ,label: 'Tutor', name: this.request.tutor.name, email: this.request.tutor.email, phone: this.request.tutor.phone}
      ]

      const notifications = this.notifications

      this.$refs.send_report_modal.openSendModal(contacts, this.reportId, notifications)
    },
    defaultStrInputs(input, category){
      return input.tipo === 'str' && input.nombre !== 'eritrocitos' && input.nombre !== 'hemoglobina' && input.nombre !== 'hematocrito' && category.nombre !== 'leucograma'
    },
    specificBloodTestInputs(input, category){
      return input.tipo === 'str' && (input.nombre === 'eritrocitos' || input.nombre === 'hemoglobina' || input.nombre === 'hematocrito') && category.nombre !== 'leucograma'
    },
    hemopatogenos(param, allParams, value) {
      if(param.nombre === 'resultado_hemopatogenos'){
        if(value === 'Negativo') {
          const founded = allParams.findIndex(el => el.nombre === 'resultado_esp_hemopatogenos')
        }
      }
    },
    bloodTest: debounce(function(category) {
      if(category.nombre === "hemograma"){
        const allParams = category.parametros

        const paramMap = {
          hematocrito: null,
          eritrocitos: null,
          hemoglobina: null,
          vcm: null,
          hcm: null,
          chcm: null
        };

        // Iterar sobre los parámetros y asignar los valores a las variables correspondientes
        for (const param of allParams) {
          if (param.nombre in paramMap) {
            paramMap[param.nombre] = param;
          }
        }
        const { hematocrito, eritrocitos, hemoglobina, vcm, hcm, chcm } = paramMap;

        // Realizar los cálculos y asignaciones correspondientes
        if(hematocrito.valor && eritrocitos.valor){
          vcm.valor = Math.round((hematocrito.valor / (eritrocitos.valor / 100000)) * 100)
        }
        if(hemoglobina.valor && eritrocitos.valor){
          hcm.valor = Math.round((hemoglobina.valor / (eritrocitos.valor / 100000)) * 100)
        }
        if(hemoglobina.valor && hematocrito.valor){
          chcm.valor = Math.round((hemoglobina.valor / hematocrito.valor) * 100)
        }
        this.$forceUpdate()
      }
    }, 400),
    leukogramPercentages: debounce(function(allParams){
      const leucocitos = allParams.find(el => el.nombre === "leucocitos")
      const percentages = allParams.filter(el => el.unidad === '%')
      const absoluteValues = allParams.filter(el => el.unidad === '/ul' && el.nombre !== "leucocitos")
      // Verificar que se haya obtenido el valor de leucocitos
      if (!leucocitos.valor) {
        return
      }

      for (let i = 0; i < percentages.length; i++) {
        const percent = percentages[i]
        if(percent.valor){
          const absValue = absoluteValues.find(el => el.nombre === percent.nombre + '_abs')

          if (absValue) {
            absValue.valor = Math.round((percent.valor / 100) * leucocitos.valor)
            this.$forceUpdate()
          }
        }
      }
    }, 400),
  },
  computed: {
    mostrarHemopatogenos() {
      return (allParams) => {
        const parametro = allParams.find(param => param.nombre === 'resultado_hemopatogenos');
        return Boolean(parametro && parametro.valor === 'Positivo');
      }
    }
  },
  watch: {
    status:{
      handler: function(newVal) {
        if (newVal === 2) {
          this.viewMode = true
        } else {this.viewMode = false}
      },
      deep: true
    },
  },
  components: {
    AlertPopUp,
    SendReportModal
},
  setup() {

    return {
      icons: {
        mdiDelete,
        mdiClose,
        mdiCogOutline,
        mdiSendOutline,
        mdiEyeOutline,
        mdiPencilOutline,
        mdiAlertOutline,
        mdiCheckCircleOutline,
        mdiFilePdfBox,
        mdiPoundBoxOutline
      }
    }
  }
}
</script>

<style scoped>
.hide-scroll{
  overflow-y: hidden !important;
  overflow-x: hidden;
}
.my-list {
    height: calc(100vh - 85px);
    overflow-y: auto;
    overflow-x: hidden;
  }
.my-card{
  height: calc(100vh - 85px);
  display: flex;
  overflow-y: auto;
  flex-direction: column;
}

.my-card .v-card__text{
    padding: 8px 20px;
  }

.my-card .v-card__title{
  padding: 20px   !important;
}

/* styles for screens between 1264px and 1600px */
@media screen and (max-width: 1600px) and (min-width: 1264px) {
  .actions {

    align-items: center !important;

  }
}

@media screen and (max-height: 765px) {
  .my-card .v-card__text{
    padding: 4px 12px !important;
    font-size: 0.8rem;
  }

  .my-card .v-card__title{
    padding: 10px 15px   !important;
    font-size: 1em;
  }

}

.readonly-input >>> .v-text-field__slot input{
  cursor:default !important;
}

.no-uppercase {
  text-transform: unset !important;
}

.modal-background {
  background-color: #f4f5fa;
  overflow-y: hidden;
    overflow-x: hidden;
}
.fixed-bar {
  position: sticky;
  position: -webkit-sticky; /* for Safari */
  top: 0em;
  z-index: 2;
}

.active-tab
{
  background-color: rgba(231, 227, 252, 0.87);
}
.tabs-item {
  margin-top: 1em;
  background-color: #f4f5fa !important;
  width: 100% !important;
}
.loading-circle {
  position: fixed;
  left: 50%;
  top: 3%;
  z-index: 10;
}

.border-divider {
  border-bottom: solid;
  border-color: rgba(94, 86, 105, 0.14);
  border-width: 1px;
}
</style>
