<template>
  <div>
    <v-card class="mb-1">
      <v-card-title>SOLICITUDES</v-card-title>
      <ListRequests />
    </v-card>

    </div>
</template>

<script>
import ListRequests from '@/components/LabRequest/ListRequests';
  export default {
    components:{
      ListRequests
    }
    
  }
</script>

<style lang="scss" scoped>

</style>

